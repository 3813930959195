import React from 'react'
import styled from 'styled-components'
import { useDictionary } from '../../dictionary/useDictionary'

export enum NavButtonType {
    HOME,
    ADD,
    LEARN,
}

export interface NavButtonNuProps {
    type: NavButtonType
    onClick?: () => void
}

const NavButtonContainer = styled.div`
    display: inline-block;
    margin: 0 5vw;
    text-align: center;
`
interface NavButtonProps {
    $image?: string
}
const NavButton = styled.div<NavButtonProps>`
    width: 10vw;
    height: 10vw;
    background-image: url(${(props) => `"${props.$image}"`});
    background-size: contain;
    background-repeat: no-repeat;
    display: inline-block;
`
const NavButtonLabel = styled.div`
    font-size: 3vw;
    color: #8c8c8c;
`

export const NavButtonNu = (props: NavButtonNuProps) => {
    const dictionary = useDictionary()

    const getButtonLabel = (type: NavButtonType) => {
        switch (type) {
            case NavButtonType.HOME:
                return dictionary.NAV_BUTTON_LABEL_HOME
            case NavButtonType.ADD:
                return ''
            case NavButtonType.LEARN:
                return dictionary.NAV_BUTTON_LABEL_LEARN
        }
    }

    const getButtonImage = (type: NavButtonType) => {
        switch (type) {
            case NavButtonType.HOME:
                return '/src/image/Icon Home.svg'
            case NavButtonType.LEARN:
                return '/src/image/Icon LEARN.svg'
        }
    }

    return (
        <NavButtonContainer>
            <NavButton
                onClick={props.onClick}
                $image={getButtonImage(props.type)}
            />
            <NavButtonLabel>{getButtonLabel(props.type)}</NavButtonLabel>
        </NavButtonContainer>
    )
}

export const navBgColor = '#e7e3db'
interface BigButtonContainerProps {
    $left?: string
}
const BigButtonContainer = styled.div<BigButtonContainerProps>`
    width: 30vw;
    height: 30vw;
    position: absolute;
    left: ${(props) => props.$left};
    top: -11vw;
    background-color: ${navBgColor};
    padding: 5vw;
    border-radius: 15vw;
`

interface BigButtonInnerProps {
    $iconUrl?: string
    $backgroundColor?: string
    $backgroundSize?: string
}
const BigButtonInner = styled.button<BigButtonInnerProps>`
    background-image: ${props => `url('${props.$iconUrl}')`};
    background-size: ${props => props.$backgroundSize || 'contain'};
    background-repeat: no-repeat;
    background-color: ${props => props.$backgroundColor || 'transparent'};
    background-position: center;
    width: 20vw;
    height: 20vw;
    border: none;
`

interface BigButtonProps {
    left: string
    onClick?: () => void
    iconUrl?: string
    backgroundColor?: string
    backgroundSize?: string
}

export const BigButton = (props: BigButtonProps) => {
    return (
        <BigButtonContainer $left={props.left}>
            <BigButtonInner
                onClick={props.onClick}
                $iconUrl={props.iconUrl}
                $backgroundColor={props.backgroundColor}
                $backgroundSize={props.backgroundSize}
            />
        </BigButtonContainer>
    )
}
