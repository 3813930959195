import { Familiarity, ITestResult } from 'czapp-shared';
import React from "react"
import styled from 'styled-components';
import { CUSTOM_BUTTON_LABEL, CustomButton } from '../generic/CustomButton';


interface ResultToastProps {
    result: ITestResult
    answer: string
    referenceAnswer: string
    onDismissClicked: () => void
}

const Toaster = styled.div`
    position: absolute;
    height: 100vh;
    width: 100vw;
    padding: 30vh 10vw;
    vertical-align: middle;
    text-align: center;
    font-weight: bold;
    z-index: 100;
    top: 0vh;
    background-color: rgba(0, 0, 0, .5);
    div {
        display: block;
        background-color: white;
        padding: 5vw;
        border-radius: 5vw;
    }
    p {
        font-size: 10vw;
        &.normal {
            color: black;
            font-size: 5vw;
        }
        &.correct {
            color: green;
        }
        &.almost {
            color: orange;
        }
        &.unknown {
            color: red;
        }
        &.reference {
            font-weight: bold;
            font-size: 7.5vw
        }
    }
`


export const ResultToast = (props: ResultToastProps) => {
    return (<Toaster>
        <div>
            {props.result.lexemePair.familiarity === Familiarity.KNOWN && <span className="correct">Correct</span>}

            {props.result.lexemePair.familiarity === Familiarity.ALMOST &&
                <>
                    <p className="almost">Almost!</p>
                    <p className="normal">You entered {props.answer}</p>
                    <p className="normal">The correct answer is...</p>
                    <p className="reference">"{props.referenceAnswer}"</p>
                </>
            }

            {props.result.lexemePair.familiarity === Familiarity.UNKNOWN &&
                <>
                    <p className="unknown">Wrong!</p>
                    <p className="normal">You entered {props.answer}</p>
                    <p className="normal">The correct answer is...</p>
                    <p className="reference">"{props.referenceAnswer}"</p>
                </>
            }

            <CustomButton
                onClick={props.onDismissClicked}
                size="10vw"
                label={CUSTOM_BUTTON_LABEL.arrowForward}
            ></CustomButton>
        </div>
    </Toaster>)
}
