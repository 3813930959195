import {
    AppError,
    getMysqlDateIgnoreTZ,
    ILexemePair,
    ITestResult,
    VocabCheckType,
} from 'czapp-shared'
import React, {
    useContext,
    useEffect,
    useReducer,
    useState,
} from 'react'
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useDictionary } from '../../dictionary/useDictionary'
import { Api } from '../../util/Api'
import { CustomButton } from '../generic/CustomButton';
import { EmptyDictionary } from '../generic/EmptyDictionary'
import { AppContext } from '../RootComponent'
import { FlashCardQuestion } from '../subcomponent/FlashCardQuestion'
import { TestQuestion } from '../subcomponent/TestQuestion'
import { Results } from './Results'
import { VcAction, vocabCheckReducer } from './vocabCheckReducer';

const HelpLabel = styled.p`
    cursor: pointer;
    color: blue;
    margin-bottom: 10vw;
`

export enum TestStatus {
    NOT_STARTED = 'NOT_STARTED',
    SHOW_QUESTION = 'SHOW_QUESTION',
    ENDED = 'ENDED',
    EMPTY_DICTIONARY = 'EMPTY_DICTIONARY',
    NO_CARDS_SELECTED = 'NO_CARDS_SELECTED',
    ALL_RETIRED = 'ALL_RETIRED'
}

export interface QuestionProps {
    currentIndex: number
    lexemePair: ILexemePair
    testLength: number
    onResult: (result: ITestResult) => Promise<void>
}

interface VocabCheckProps {
    vocabCheckType: VocabCheckType
}

export const VocabCheck = (props: VocabCheckProps) => {
    const context = useContext(AppContext)
    const dictionary = useDictionary()
    const navigate = useNavigate()

    const [helpTextOpen, setHelpTextOpen] = useState(false)

    const [state, dispatch] = useReducer( vocabCheckReducer, {
        lexemePairs: [],
        status: TestStatus.NOT_STARTED,
        currentIndex: 0,
        currentAnswers: [],
        results: [],
        userData: context.loggedInUser,
        rewardPointsDelta: 0,
        testResultIds: [],
    })

    useEffect(() => {
        ;(async () => {
            switch (state.status) {

                case TestStatus.NOT_STARTED:
                    try {
                        if (props.vocabCheckType === VocabCheckType.FLASHCARD) {
                            const { data } = await Api.getVocabCheckLexemes(VocabCheckType.FLASHCARD)
                            dispatch({ type: VcAction.INIT_FLASHCARDS, payload: data})

                        } else {
                            const {data} = await Api.getVocabCheckLexemes(VocabCheckType.TEST)
                            // const data = {} as TestInitResponseData
                            dispatch({ type: VcAction.INIT_TEST, payload: data})
                        }
                    } catch {
                        context.setAppError(AppError.REQUEST_GET_LEXEMES_FOR_TEST_FAILED)
                    }
                    break

                case TestStatus.ENDED:
                    try {
                        const {data} = await Api.postTestComplete({
                            clientDate: getMysqlDateIgnoreTZ(new Date())!,
                            results: state.results,
                            vocabCheckType: VocabCheckType.FLASHCARD,
                        })
                        dispatch({type: VcAction.DISPLAY_RESULTS, payload: data})
                    } catch {
                        context.setAppError(AppError.REQUEST_TEST_COMPLETE_FAILED)
                    }
                    break

                default:
                    break
            }
        })()
    }, [state.status])

    const onResult = async (result: ITestResult) => {
        console.log('onResult', result);
        try {
            dispatch({ type: VcAction.REGISTER_RESULT, payload: result })
            await Api.putLexemePairFamiliarity(
                result.lexemePair.czechLexeme.id!,
                result.lexemePair.englishLexeme.id!,
                result.lexemePair.familiarity
            )

        } catch {
            context.setAppError(AppError.REQUEST_UPDATE_FAMILIARITY_FAILED)
        }
    }

    const onSkipDeck = async () => {
        setHelpTextOpen(false)
        try {
            // bump the deck
            await Api.skipDeck();
            dispatch({ type: VcAction.RESTART })
        } catch {
            context.setAppError(AppError.REQUEST_TEST_SKIP_FAILED)
        }
    }

    const onAdd = () => {
        setHelpTextOpen(false)
        navigate('/home/add')
    }

    const renderQuestion = () => {
        return props.vocabCheckType === VocabCheckType.FLASHCARD ? (
            <FlashCardQuestion
                lexemePair={state.lexemePairs[state.currentIndex]}
                currentIndex={state.currentIndex}
                onResult={onResult}
                testLength={state.lexemePairs.length}
            />
        ) : (
            <TestQuestion
                lexemePair={state.lexemePairs[state.currentIndex]}
                currentIndex={state.currentIndex}
                onResult={onResult}
                testLength={state.lexemePairs.length}
            />
        )
    }

    const renderResults = () => <Results
        testResults={state.results}
        userData={state.userData}
        rewardPointsDelta={state.rewardPointsDelta}
        testResultIds={state.testResultIds}
    />

    const renderEmptyDictionary = () => <EmptyDictionary />

    const renderNoCardsSelected = () => <>
        <h2>{dictionary.TEST_NO_CARDS_SELECTED}</h2>
        <HelpLabel onClick={() => setHelpTextOpen((prev) => !prev)}>{dictionary.TEST_NO_CARDS_SELECTED_HELP_LABEL}</HelpLabel>
        {helpTextOpen && <div dangerouslySetInnerHTML={{__html: dictionary.TEST_NO_CARDS_SELECTED_HELP_TEXT}} />}
        <CustomButton size="15vw" label="skip_next" onClick={onSkipDeck} />
        <CustomButton size="15vw" label="add" onClick={onAdd} />
    </>

    const renderAllRetired = () => <>
        <h2>{dictionary.TEST_ALL_RETIRED}</h2>
        <HelpLabel onClick={() => setHelpTextOpen((prev) => !prev)}>{dictionary.TEST_ALL_RETIRED_HELP_LABEL}</HelpLabel>
        {helpTextOpen && <div dangerouslySetInnerHTML={{__html: dictionary.TEST_ALL_RETIRED_HELP_TEXT}} />}
        <CustomButton size="15vw" label="add" onClick={onAdd} />
    </>

    return (
        <>
            <h1>
                {props.vocabCheckType === VocabCheckType.FLASHCARD
                    ? dictionary.PAGETITLE_FLASHCARD_REVIEW
                    : dictionary.PAGETITLE_TESTS}
            </h1>
            <>
                {state.status === TestStatus.SHOW_QUESTION && renderQuestion()}
                {state.status === TestStatus.ENDED && renderResults()}
                {state.status === TestStatus.EMPTY_DICTIONARY && renderEmptyDictionary()}
                {state.status === TestStatus.NO_CARDS_SELECTED && renderNoCardsSelected()}
                {state.status === TestStatus.ALL_RETIRED && renderAllRetired()}
            </>
        </>
    )
}
