import { Progress, ITestResult, IUserData } from 'czapp-shared'
import * as React from 'react'
import { useDictionary } from '../../dictionary/useDictionary'
import { Navigate } from 'react-router'

interface IResultStats {
    worse: number
    noProgress: number
    improved: number
}

interface IResultsProps {
    testResults: Array<ITestResult>
    userData: IUserData | undefined
    rewardPointsDelta: number
    testResultIds: Array<number>
}

export const Results = (props: IResultsProps) => {
    const dictionary = useDictionary()
    const [backToTestsButtonClicked, setBackToTestsButtonClicked] =
        React.useState(false)

    if (backToTestsButtonClicked) return <Navigate to="/learn" />

    const getStats = (): IResultStats => {
        let worse = 0
        let noProgress = 0
        let improved = 0

        props.testResults.forEach((item) => {
            switch (item.progress) {
                case Progress.WORSE:
                    worse++
                    break

                case Progress.IMPROVED:
                    improved++
                    break

                default:
                    noProgress++
            }
        })

        return {
            worse: worse,
            noProgress: noProgress,
            improved: improved,
        } as IResultStats
    }

    const renderTitle = () => {
        return <h2>{dictionary.RESULTS_SUMMARY_TITLE}</h2>
    }

    const renderSummary = () => {
        const stats = getStats()
        return (
            <div className="summary">
                <div className="summaryItem">
                    {dictionary.RESULTS_SUMMARY_ITEM_IMPROVED}: {stats.improved}
                </div>
                <div className="summaryItem">
                    {dictionary.RESULTS_SUMMARY_ITEM_NO_PROGRESS}:{' '}
                    {stats.noProgress}
                </div>
                <div className="summaryItem">
                    {dictionary.RESULTS_SUMMARY_ITEM_WORSE}: {stats.worse}
                </div>
                <div className="summaryItem">
                    {dictionary.RESULTS_SUMMARY_CURRENT_STREAK}:{' '}
                    {props.userData?.currentStreakLength}
                </div>
                <div className="summaryItem">
                    {dictionary.RESULTS_SUMMARY_HIGH_STREAK}:{' '}
                    {props.userData?.highStreakLength}
                </div>
                <div className="summaryItem">
                    {dictionary.RESULTS_SUMMARY_HIGH_STREAK_END_DATE}:{' '}
                    {props.userData && new Date(props.userData.highStreakEnd!).toDateString()}
                    {!props.userData && "N/A"}
                </div>
                <div className="summaryItem">
                    {dictionary.RESULTS_SUMMARY_TESTS_COMPLETED_COUNT}:{' '}
                    {props.userData?.testsCompletedCount}
                </div>
                <div className="summaryItem">
                    flashcard reviews done:{' '}
                    {props.userData?.flashcardReviewCompletedCount}
                </div>
                <div className="summaryItem">
                    reward points total: {props.userData?.rewardPoints}
                </div>
                <div className="summaryItem">
                    reward points delta: {props.rewardPointsDelta}
                </div>
                <div className="summaryItem">
                    result ids: {props.testResultIds.join(', ')}
                </div>
            </div>
        )
    }

    return (
        <div>
            {renderTitle()}
            {renderSummary()}
        </div>
    )
}
