import React from 'react'
import styled from 'styled-components'
import { CustomButton } from '../generic/CustomButton'

const FullPageOverlayContainer = styled.div`
    width: 100vw;
    height: 100vh;
    padding: 5vh 5vw;
    background-color: #3f3939;
    position: absolute;
    z-index: 100;
    overflow-y: auto;
    text-align: center;
`

const CloseButtonContainer = styled.div`
    position: relative;
    display: block;
    text-align: right;
`

export interface FullPageOverlayProps {
    closeButtonLabel: string
    onClose: () => void
    children: React.ReactNode
}

export const FullPageOverlay = (props: FullPageOverlayProps) => {
    return (
        <FullPageOverlayContainer>
            <CloseButtonContainer>
                <CustomButton
                    label={props.closeButtonLabel}
                    onClick={props.onClose}
                />
            </CloseButtonContainer>
            {props.children}
        </FullPageOverlayContainer>
    )
}
