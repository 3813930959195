import React from 'react'
import styled from 'styled-components'
import { ActiveOverlay } from '../App';
import { Card, CardSize } from '../layout/Card'
import { CardRow } from '../layout/CardRow';
import { AppContext } from '../RootComponent'

const StreakIcon = styled.div`
    background-image: url('/src/image/Icon STREAK.svg');
    background-size: contain;
    background-repeat: no-repeat;
    margin-top: 1.5vw;
    margin-right: 1vw !important;
    margin-bottom: 0 !important;
    width: 11vw;
    height: 11vw;
    display: inline-block;
`
const StreakNumber = styled.div`
    font-size: 11vw;
    font-weight: bold;
    color: #76b2cb;
    display: inline-block;
    margin-bottom: 0 !important;
`

const StreakMessage = styled.div`
    font-size: 2.5vw;
    color: #8c8c8c;
    margin-top: 2vw;
    font-weight: bold;
    width: 100%;
`

export const StreakCard = () => {
    const context = React.useContext(AppContext)
    return (
        <Card
            onClick={() => context.setActiveOverlay(ActiveOverlay.STREAK)}
            title="Streak"
            size={CardSize.HALF}>
            <CardRow>
                <StreakIcon />
                <StreakNumber>
                    {context.loggedInUser?.currentStreakLength}
                </StreakNumber>
            </CardRow>
            <StreakMessage>
                Highest streak: {context.loggedInUser?.highStreakLength} days
            </StreakMessage>
        </Card>
    )
}
