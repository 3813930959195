import React from "react"
import styled from 'styled-components';

const ProgressContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 5vw;
    margin-bottom: 6vw;
    width: 100%;
`
const ProgressText = styled.div`
    font-size: 5vw;
    margin-right: 5vw;
    display: inline-block;
    color: #d99a1c;
`
const ProgressMeterOuter = styled.div`
    width: 70%;
    height: 5vw;
    background-color: #f1f1f1;
    border-radius: 2.5vw;
    overflow: hidden;
    margin: 5vw 0;
    box-shadow: 0 0 5vw #00000033;
`
const ProgressMeterInner = styled.div`
    height: 100%;
    background-color: #d99a1c;
`

export interface TestProgressMeterProps {
    currentQuestion: number
    totalQuestions: number
}
export const TestProgressMeter = (props: TestProgressMeterProps) => {
    return (
        <ProgressContainer>
            <ProgressText>{props.currentQuestion} / {props.totalQuestions}</ProgressText>
            <ProgressMeterOuter>
                <ProgressMeterInner
                    className="progressMeterInner"
                    style={{ width: `${props.currentQuestion * 100 / props.totalQuestions}%` }}
                ></ProgressMeterInner>
            </ProgressMeterOuter>
        </ProgressContainer>
    )
}
