import { ILexemePair, Language } from 'czapp-shared';
import React, { useContext } from 'react';
import { LanguageIdentifier } from '../generic/LanguageIdentifier';
import { AppContext } from '../RootComponent';
import { QuestionOrAnswerSection } from './QuestionOrAnswerSection';

interface QuestionProps {
    lexemePair: ILexemePair
}

type QuestionData = {
    text: string
    language: Language
}

const getQuestionData = (lexemePair: ILexemePair, learningLanguage: Language): QuestionData => {
    return {
        text: learningLanguage === Language.CZECH
            ? lexemePair.englishLexeme.text
            : lexemePair.czechLexeme.text,
        language: learningLanguage === Language.ENGLISH
            ? Language.CZECH
            : Language.ENGLISH
    }
}

export const Question = (props: QuestionProps) => {
    const context = useContext(AppContext)
    const questionData = getQuestionData(props.lexemePair, context.learningLanguage || Language.ENGLISH)
    return <>
        <LanguageIdentifier language={questionData.language} $left={'3vw'} $top={'6vw'} $size={'12vw'}/>
        <QuestionOrAnswerSection>
            <span className="text">{questionData.text}</span>
        </QuestionOrAnswerSection>
    </>
}
