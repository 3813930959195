import React from 'react'
import styled from 'styled-components'

interface MainPageContentProps {
    children: React.ReactNode
}
const ScrollingContentContainer = styled.div`
    overflow-y: auto;
    height: 89vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 8vw 5vw;
`
const InnerContainer = styled.div`
    padding-bottom: 5vh;
`

export const MainPageContent = (props: MainPageContentProps) => {
    return (
        <ScrollingContentContainer className="scrollingContainer">
            <InnerContainer>{props.children}</InnerContainer>
        </ScrollingContentContainer>
    )
}
