import React from 'react'
import styled from 'styled-components'

const CzappLogoElement = styled.div`
    background-image: url('/src/image/Logo Light.svg');
    background-size: cover;
    background-repeat: no-repeat;
    width: calc(221vw / 7);
    height: calc(52vw / 7);
    margin: 3vh auto;
`

export const CzappLogo = () => {
    return <CzappLogoElement />
}
