import React from 'react'
import styled from 'styled-components'
import { Card, CardSize } from '../layout/Card'
import { AppContext } from '../RootComponent'

const LastWordAdded = styled.div`
    font-size: 3.5vw;
    margin-top: 2vw;
    font-weight: 100;
    b {
        font-weight: 900;
    }
`
const LexemeNumber = styled.div`
    font-size: 12vw;
    font-weight: bold;
    display: inline-block;
    text-align: center;
`
const LexemeLabel = styled.div`
    font-size: 5vw;
    margin-top: 2vw;
    padding-left: 2vw;
    font-weight: bold;
    display: inline-block;
`
const CardRowInner = styled.div`
    width: 50%;
    margin: 3vw auto;
    color: white;
    float: right;
`

interface DictionaryCardProps {
    onClick: () => void
}

export const DictionaryCard = (props: DictionaryCardProps) => {
    const context = React.useContext(AppContext)
    return (
        <Card
            onClick={() => props.onClick()}
            size={CardSize.FULL}
            backgroundColor="#317a97"
            backgroundImageUrl="/src/image/Icon Dictionary.svg"
        >
            <CardRowInner>
                <div>
                    <LexemeNumber>
                        {context.loggedInUser?.lexemePairCount}
                    </LexemeNumber>
                    <LexemeLabel>entries</LexemeLabel>
                </div>
                <LastWordAdded>
                    <b>Last word added:</b> {context.loggedInUser?.lastWordAdded}
                </LastWordAdded>
            </CardRowInner>
        </Card>
    )
}
