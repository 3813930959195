import * as Sentry from '@sentry/react'

export class Logger {
    static log = (message: string, ...args: unknown[]) => {
        console.log(message, ...args)
    }

    static error = (message: string, ...args: unknown[]) => {
        console.log('Sentry capturing message')
        if (process.env.DEV_MODE === 'false')
            Sentry.captureMessage(message, 'error')
        console.error(message, ...args)
    }

    static warn = (message: string, ...args: unknown[]) => {
        console.warn(message, ...args)
    }

    static info = (message: string, ...args: unknown[]) => {
        console.info(message, ...args)
    }

    static debug = (message: string, ...args: unknown[]) => {
        console.debug(message, ...args)
    }

    static exception = (e: Error) => {
        if (process.env.DEV_MODE === 'false') Sentry.captureException(e)
        Logger.error(e.message, e)
    }
}
