import React from "react"
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    border: 2px solid #bbbaba;
    background-color: #296981;
    border-radius: 10px;
    font-size: 8vw;
    width: 100%;
    padding: 12vw 5vw;
    color: white;
    .multiple {
        margin-bottom: 5vw;
    }
    .text {
        font-size: 8vw;
        width: 100%;
        text-align: center;
        .index {
            color: #bbbaba;
        }
    }
`
interface QuestionOrAnswerSectionProps {
    children: React.ReactNode
}

export const QuestionOrAnswerSection = (props: QuestionOrAnswerSectionProps) => {
    return <Container>
        {props.children}
    </Container>
}
