import { Language } from 'czapp-shared'
import { useContext } from 'react'
import { AppContext } from '../components/RootComponent'
import { IDictionary } from './IDictionary'
import { Cz } from './Cz'
import { En } from './En'

export const useDictionary = (): IDictionary => {
    const context = useContext(AppContext)
    return context.uiLanguage === Language.ENGLISH ? En : Cz
}
