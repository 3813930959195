import { ControllerResponse, HttpStatusCode } from 'czapp-shared'
import LocalStorage from './LocalStorage'
import { Logger } from './Logger'

class ApiRequestError extends Error {
    status: HttpStatusCode
    constructor(status: HttpStatusCode, message: string) {
        super(message)
        this.status = status
    }
}

const REQUEST_TIMEOUT = 5_000

export default class LoaderUtil {
    public static request = async <T>(
        path: string,
        method: string,
        body?: object
    ): Promise<ControllerResponse<T>> => {
        const controller = new AbortController()
        let requestTimeout = false

        const id = setTimeout(() => {
            Logger.error(
                `REQUEST TIMEOUT: ${path} ${method} timed out after ${REQUEST_TIMEOUT}ms`
            )
            controller.abort()
            requestTimeout = true
        }, REQUEST_TIMEOUT)

        const headers = {
            Authorization: LocalStorage.getCredential(),
            'Content-Type': 'application/json',
        }

        const response = await fetch(process.env.CZAPP_SERVER_BASE_URL + path, {
            method,
            headers,
            signal: controller.signal,
            body: JSON.stringify(body),
            credentials: 'include',
        })

        if (requestTimeout) {
            Logger.warn('[request] Request TIMED OUT')
            throw new ApiRequestError(
                HttpStatusCode.TIMEOUT,
                'Request timed out'
            )
        }

        clearTimeout(id)

        if (!response.ok) {
            Logger.warn('[request] Response not OK', response.statusText)
            throw new ApiRequestError(response.status, response.statusText)
        }

        const contentType = response.headers.get('content-type')

        // If the response is HTML, return the text
        if (contentType?.includes('text/html')) {
            throw new ApiRequestError(
                HttpStatusCode.BAD_REQUEST,
                'Response is HTML'
            )
        }

        // If the response is JSON, parse and return it
        return (await response.json()) as ControllerResponse<T>
    }
}
