import '../style/App.css'
import {
    AppError,
    ControllerResponse,
    Language,
    LexemePairWithLang,
    MySQLDate,
    ReminderFrequency,
    IUserData,
    getMysqlDateIgnoreTZ,
} from 'czapp-shared'
import { createContext, useEffect, useState } from 'react'
import * as React from 'react'
import LocalStorage, { LS } from '../util/LocalStorage'
import { shouldShowReminder } from '../util/Streak'
import { ActiveOverlay, App } from './App'
import { Api, devModeUserData } from '../util/Api'
import { SignInPage } from './SignInPage';

export enum TopLevelPage {
    HOME = 'home',
    LEARN = 'learn',
}

export type AppContext = {
    activeTopLevelPage: TopLevelPage
    setActiveTopLevelPage: (toplevelPage: TopLevelPage) => void

    activeOverlay: ActiveOverlay
    setActiveOverlay: (overlay: ActiveOverlay) => void

    learningLanguage: Language
    setLearningLanguage: (language: Language) => void

    uiLanguage: Language
    setUiLanguage: (language: Language) => void

    oneSignalInitialized: boolean
    setOneSignalInitialized: (initialized: boolean) => void

    // TODO - remove this duplication of state. The `users` table in the database should be the only source of truth for
    //  this data. Keeping this state in sync with the database relies on timely polling. A better solution would be to
    //  employ some solution that uses a WebSocket connection to keep the client in sync with the database server.
    loggedInUser: IUserData | undefined
    setLoggedInUser: (user: IUserData | undefined) => void

    showStreakReminderOverlay: boolean
    setShowStreakReminderOverlay: (show: boolean) => void

    currentlyEditingLexemePair: LexemePairWithLang | undefined
    setCurrentlyEditingLexemePair: (
        idPairWithLang: LexemePairWithLang | undefined
    ) => void

    appError: AppError
    setAppError: (error: AppError) => void
}

export const AppContext = createContext<AppContext>(
    null as unknown as AppContext
)

export const RootComponent = () => {

    const [activeTopLevelPage, setActiveTopLevelPage] = useState<TopLevelPage>(TopLevelPage.HOME)
    const [activeOverlay, setActiveOverlay] = useState<ActiveOverlay>(ActiveOverlay.NONE)
    const [learningLanguage, setLearningLanguage] = useState(Language.UNKNOWN)
    const [uiLanguage, setUiLanguage] = useState(Language.UNKNOWN)
    const [oneSignalInitialized, setOneSignalInitialized] = useState<boolean>(false)
    const [loggedInUser, setLoggedInUser] = useState<IUserData | undefined>(undefined)
    const [autoLoginResponded, setAutoLoginResponded] = useState<boolean>(false)
    const [showStreakReminderOverlay, setShowStreakReminderOverlay] = useState<boolean>(false)
    const [currentlyEditingLexemePair, setCurrentlyEditingLexemePair] = useState<LexemePairWithLang | undefined>(undefined)
    const [appError, setAppError] = useState<AppError>(AppError.NONE)

    const initPage = async () => {
        if (process.env.DEV_MODE === 'true') setLoggedInUser(devModeUserData)
        const clientDate: MySQLDate = getMysqlDateIgnoreTZ(new Date())!

        try {
            const { data }: ControllerResponse<IUserData> = await Api.autologin({ clientDate })

            if (data) {
                const {
                    learningLanguage,
                    uiLanguage,
                    currentStreakEnd,
                    currentStreakLength,
                } = data
                setLearningLanguage(learningLanguage!)
                setUiLanguage(uiLanguage!)

                const today: MySQLDate = getMysqlDateIgnoreTZ(new Date())!
                const lastShownDate: MySQLDate | null = LocalStorage.getKey(
                    LS.TEST_REMINDER_LAST_SHOWN_DATE,
                    null
                ) as MySQLDate | null
                const reminderFrequency: ReminderFrequency =
                    LocalStorage.getKey(
                        LS.DEVICE_TEST_REMINDER_FREQUENCY,
                        ReminderFrequency.DAILY
                    ) as ReminderFrequency

                const shouldShowReminderOptions = {
                    currentStreakLength: currentStreakLength!,
                    currentStreakEnd: currentStreakEnd!,
                    today,
                    lastShownDate,
                    reminderFrequency,
                }
                const showReminder = shouldShowReminder(
                    shouldShowReminderOptions
                )
                if (showReminder) {
                    LocalStorage.setKey(LS.TEST_REMINDER_LAST_SHOWN_DATE, today)
                    setShowStreakReminderOverlay(true)
                }

                setLoggedInUser(data)
            }

        } finally {
            setAutoLoginResponded(true)
        }
    }

    const onVisibilityChange = async () => {
        if (!document.hidden) {
            setAutoLoginResponded(false)
            await initPage()
        }
    }

    useEffect(() => {
        ;(async () => {
            await initPage()
            // TODO - uncomment before merging to master
            document.addEventListener('visibilitychange', onVisibilityChange)
        })()
        return () => {
            // TODO - uncomment before merging to master
            document.removeEventListener('visibilitychange', onVisibilityChange)
        }
    }, [])

    return (
        <AppContext.Provider
            value={{
                activeTopLevelPage,
                setActiveTopLevelPage,

                activeOverlay,
                setActiveOverlay,

                learningLanguage,
                setLearningLanguage,

                uiLanguage,
                setUiLanguage,

                oneSignalInitialized,
                setOneSignalInitialized,

                loggedInUser,
                setLoggedInUser,

                showStreakReminderOverlay,
                setShowStreakReminderOverlay,

                currentlyEditingLexemePair,
                setCurrentlyEditingLexemePair,

                appError,
                setAppError,
            }}
        >
            {loggedInUser && <App />}
            {!autoLoginResponded && <p>Loading...</p>}
            {autoLoginResponded && !loggedInUser && <SignInPage />}
        </AppContext.Provider>
    )
}
