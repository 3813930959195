import React, { useEffect } from 'react'
import styled from 'styled-components'
import { CUSTOM_BUTTON_LABEL, CustomButton } from '../generic/CustomButton'

interface OverlayContainerProps {
    $hidden: boolean
    $bgColor?: string
}

const OverlayContainer = styled.div<OverlayContainerProps>`
    background-color: ${(props) => props.$bgColor};
    position: absolute;
    width: 100vw;
    height: 96vh;
    left: 0;
    padding: 7vw;
    border-top-left-radius: 10vw;
    transition: top .3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    border-top-right-radius: 10vw;
    top: ${(props) => props.$hidden ? '100vh' : '4vh'};
    outline: 1vw solid #80bda6;
`

const OverlayInner = styled.div`
    position: relative;
    width: 100%;
    height: 90%;
    overflow-y: auto;
`

interface OverlayProps {
    bgColor?: string
    children: React.ReactNode
    onClose: () => void
}

export const Overlay = (props: OverlayProps) => {
    const [hidden, setHidden] = React.useState(true)
    useEffect(() => {
        setHidden(false)
    }, [])

    return (
        <OverlayContainer
            $bgColor={props.bgColor}
            $hidden={hidden}
            className="overlay-container">
            <OverlayInner className="overlay-inner">
                <CustomButton
                    label={CUSTOM_BUTTON_LABEL.close}
                    onClick={() => {
                        setHidden(true)
                        setTimeout(() => {
                            props.onClose()
                        }, 300)
                    }}
                    floatRight={true}
                    size="10vw"
                />
                {props.children}
            </OverlayInner>
        </OverlayContainer>
    )
}
