import { Language } from 'czapp-shared'
import React from 'react'
import styled from 'styled-components'

const EN_FLAG_IMG_URL: string = '/src/image/icon/flag_uk-cropped.svg'
const CZ_FLAG_IMG_URL: string = '/src/image/icon/flag_cz-cropped.svg'

interface FlagDivProps {
    $url: string
    $top?: string
    $left?: string
    $size?: string
}

const FlagDiv = styled.div<FlagDivProps>`
    background-image: url(${(props) => props.$url});
    position: relative;
    //border: 0.5vw solid white;
    top: ${(props) => (props.$top ? props.$top : '0')};
    left: ${(props) => (props.$left ? props.$left : '0')};
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50%;
    border-radius: 50%;
    width: ${(props) => (props.$size ? props.$size : '15vw')};
    height: ${(props) => (props.$size ? props.$size : '15vw')};
    z-index: 10;
}
`

export interface LanguageIdentifierProps {
    language: Language
    $top?: string
    $left?: string
    $size?: string
}

export const LanguageIdentifier = (props: LanguageIdentifierProps) => {
    return props.language === Language.ENGLISH ? (
        <FlagDiv $url={EN_FLAG_IMG_URL} $top={props.$top} $left={props.$left} $size={props.$size} />
    ) : (
        <FlagDiv $url={CZ_FLAG_IMG_URL} $top={props.$top} $left={props.$left} $size={props.$size} />
    )
}
