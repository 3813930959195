import { Language } from 'czapp-shared'
import React from 'react'
import styled from 'styled-components'

export interface InputLanguageButtonProps {
    language: Language
    onClick: () => void
    ref?: React.RefObject<HTMLButtonElement>
}

const EN_FLAG_IMG_URL: string = '/src/image/icon/flag_uk.svg'
const CZ_FLAG_IMG_URL: string = '/src/image/icon/flag_cz.svg'

interface FlagButtonProps {
    $url: string
}

const FlagButton = styled.button<FlagButtonProps>`
    background-image: url(${(props) => props.$url});
    background-size: min(20vw, 20vh);
    background-position: center;
    color: #3b3938;
    border-radius: 50%;
    width: min(15vw, 13vh);
    height: min(15vw, 13vh);
    position: relative;
    top: 7vh;
    border: 1vw white solid;
    z-index: 10;
`

export const InputLanguageButton = (props: InputLanguageButtonProps) => {
    return (
        <>
            {props.language === Language.ENGLISH && (
                <FlagButton
                    $url={EN_FLAG_IMG_URL}
                    onClick={props.onClick}
                    ref={props.ref}
                />
            )}
            {props.language === Language.CZECH && (
                <FlagButton
                    $url={CZ_FLAG_IMG_URL}
                    onClick={props.onClick}
                    ref={props.ref}
                />
            )}
        </>
    )
}
