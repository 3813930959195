import { IDictionary } from './IDictionary'

export const En: IDictionary = {
    ALERT_LEXEME_PAIR_ALREADY_EXISTS:
        'This lexeme pair was already added to your dictionary on date ',
    APP_TITLE: 'CZAPP',
    APP_SUBTITLE: 'Your personal training dictionary',
    APP_SUBTITLE_2: 'Please sign in with a Google account',
    BUTTON_ADD_CZECH: 'Add Czech',
    BUTTON_ADD_ENGLISH: 'Add English',
    BUTTON_BACK_TO_TESTS: 'Back to tests',
    BUTTON_CLEAR: 'Clear',
    BUTTON_CLOSE: 'Close',
    BUTTON_DELETE: 'Delete',
    BUTTON_DELETE_ALL_USER_DATA: 'Delete all data',
    BUTTON_EXPORT_CSV: 'Export CSV',
    BUTTON_HIDE_METADATA: 'hide metadata',
    BUTTON_RATE_AS_FAMILIAR: 'Almost know',
    BUTTON_RATE_AS_KNOWN: 'Got it!',
    BUTTON_RATE_AS_UNKNOWN: "Don't know",
    BUTTON_RECENT: 'Recent words',
    BUTTON_REVEAL_ANSWER: 'Show answer',
    BUTTON_SAVE: 'Save',
    BUTTON_SHOW_METADATA: 'show metadata',
    BUTTON_SIGN_OUT: 'Sign out',
    BUTTON_START_AGAIN: 'Start again',
    BUTTON_START_TEST: 'Start test',
    BUTTON_SUBSCRIBE_PUSH: 'Subscribe to daily reminders',
    BUTTON_UNSUBSCRIBE_PUSH: 'Unsubscribe from daily reminders',
    BUTTON_UPDATE: 'Update',
    BUTTON_SUGGEST_TRANSLATION: 'Suggest translation',
    BUTTON_SWITCH_LANGUAGES: 'Switch languages',
    BUTTON_TRANSLATE: 'Translate',
    CHECKBOX_REMEMBER_ME: 'Remember me',
    CONFIRM_DELETE_LEXEME_PAIR:
        'Are you sure you want to delete this translation pairing?',
    CONFIRM_WORD_ADDED: 'Words added',
    CONFIRM_WORD_UPDATED: 'Dictionary updated',
    CZ_GENDER_OPTION_FEMININE: 'feminine',
    CZ_GENDER_OPTION_MASCULINE: 'masculine',
    CZ_GENDER_OPTION_MASCULINE_ANIMATUM: 'masculine animatum',
    CZ_GENDER_OPTION_NEUTER: 'neuter',
    CZ_GENDER_SELECT_LABEL: 'Gender',
    CZ_VERB_ASPECT_OPTION_IMPERFECTIVE: 'imperfective',
    CZ_VERB_ASPECT_OPTION_PERFECTIVE: 'perfective',
    CZ_VERB_ASPECT_SELECT_LABEL: 'Czech verb aspect',
    ERROR_OVERLAY_HEADING: 'Something went wrong',
    INLINE_ERROR_MSG_TRANSLATIONS_UNAVAILABLE:
        'Translation could not be auto-loaded',
    LANGUAGE_TO_TEST_OPTION_CZ: 'Czech',
    LANGUAGE_TO_TEST_OPTION_EN: 'English',
    LANGUAGE_TO_TEST_SELECT_LABEL: 'language to test',
    LEXEME_TYPE_OPTION_PHRASE: 'phrase',
    LEXEME_TYPE_OPTION_UNKNOWN: 'unknown',
    LEXEME_TYPE_OPTION_WORD: 'word',
    MESSAGE_DICTIONARY_EMPTY: 'Dictionary empty',
    MICROPHONE_ACCESS_DENIED: 'Please allow microphone access',
    NAV_BUTTON_LABEL_ADD: 'Add',
    NAV_BUTTON_LABEL_HOME: 'Dictionary',
    NAV_BUTTON_LABEL_LEARN: 'Review',
    PAGETITLE_DELETE_ALL_DATA: 'Delete All Data',
    PAGETITLE_RECENT: 'Your Dictionary',
    PAGETITLE_ADD: 'Add',
    PAGETITLE_EDIT: 'Edit',
    PAGETITLE_FLASHCARD_REVIEW: 'Review',
    PAGETITLE_TESTING: 'Testing...',
    PAGETITLE_TESTS: 'Vocab Check',
    PAGETITLE_SETTINGS: 'Settings',
    PHRASE_TYPE_OPTION_COLLOQUIALISM: 'colloquialism',
    PHRASE_TYPE_OPTION_IDIOM: 'idiom',
    PHRASE_TYPE_OPTION_OTHER: 'other',
    PHRASE_TYPE_OPTION_PROVERB: 'proverb',
    PHRASE_TYPE_OPTION_UNKNOWN: 'unknown',
    PHRASE_TYPE_SELECT_LABEL: 'Phrase type',
    PLACEHOLDER_INPUT_IN_CZECH: 'Czech text',
    PLACEHOLDER_INPUT_IN_ENGLISH: 'English text',
    PLACEHOLDER_TRANSLATION: 'Suggested translation',
    PLACEHOLDER_TRANSLATION_LOADING: 'Loading translation...',
    RECORDING_INSTRUCTIONS: 'Tap to stop',
    REMINDER_FREQUENCY_ALWAYS: 'Always',
    REMINDER_FREQUENCY_DAILY: 'Daily',
    REMINDER_FREQUENCY_NEVER: 'Never',
    RESULTS_SUMMARY_CURRENT_STREAK: 'Current streak',
    RESULTS_SUMMARY_HIGH_STREAK: 'High streak',
    RESULTS_SUMMARY_HIGH_STREAK_END_DATE: 'High Streak End date',
    RESULTS_SUMMARY_TITLE: 'Results',
    RESULTS_SUMMARY_ITEM_WORSE: 'Worse',
    RESULTS_SUMMARY_ITEM_NO_PROGRESS: 'No progress',
    RESULTS_SUMMARY_ITEM_IMPROVED: 'Improved',
    RESULTS_SUMMARY_TESTS_COMPLETED_COUNT: 'Number of tests completed',
    SECTION_PRE_EXISTING_TRANSLATIONS: 'Translations already saved',
    SELECT_LABEL_LEXEME_TYPE: 'Lexeme type',
    SETTINGS_LANGUAGE_OPTION_CZ: 'Czech',
    SETTINGS_LANGUAGE_OPTION_EN: 'English',
    SETTINGS_SECTION_LEARNING_LANGUAGE: 'Language to learn',
    SETTINGS_SECTION_TEST_REMINDER_FREQUENCY: 'Test reminder frequency',
    SETTINGS_SECTION_UI_LANGUAGE: 'User interface language',
    START_TEST_BUTTON: 'Start test',
    STREAK_CURRENT: 'Current streak',
    STREAK_MAX: 'Max streak',
    STREAK_MISSED_DAYS: 'Days missed since last test',
    TESTS_COMPLETED: 'Tests completed',
    TEST_ALL_RETIRED: 'All words learned',
    TEST_ALL_RETIRED_HELP_LABEL: 'What does this mean?',
    TEST_ALL_RETIRED_HELP_TEXT: 'All words have been marked "known" five times in a row.' +
        '<br/><br/>' +
        'Add more words to your dictionary to continue learning.',
    TEST_LENGTH_SELECT_LABEL: 'Test length',
    TEST_NO_CARDS_SELECTED: 'Empty flashcard deck',
    TEST_NO_CARDS_SELECTED_HELP_LABEL: 'What does this mean?',
    TEST_NO_CARDS_SELECTED_HELP_TEXT: 'Words are scheduled for review using the "Leitner" spaced repetition system.' +
        '<br/><br/>' +
        'Newly added words always appear in your flashcard decks, but words that you have marked as "known" with the green smiley will only then appear if they are scheduled by the algorithm.' +
        '<br/><br/>' +
        'The more you mark a word as "known", the less frequently it appears in the deck. If you mark a word known five times in a row then you won\'t see it any more because by then you should have it properly memorised.' +
        '<br/><br/>' +
        'If the current flashcard deck is empty then it\'s a sign that you haven\'t added so many new words recently.' +
        '<br/><br/>' +
        'You could just skip to the next deck, but you should really consider adding more new words to your learning list.',
    // TEST_NO_CARDS_SELECTED_HELP_TEXT_2: '',
    TEST_TYPE_OPTION_FLASHCARD: 'Flashcards',
    TEST_TYPE_OPTION_SPECIAL: 'Special',
    TEST_TYPE_SELECT_LABEL: 'Test type',
    TEXT_DATA_DELETED:
        'Your data has been deleted. You may start using the app again, but you will have to start from scratch.',
    TEXT_DELETE_ALL_DATA_CONFIRMATION:
        'Are you sure you want to delete your data? This action cannot be undone.',
    TEXT_DELETE_ALL_DATA_CONFIRMATION_2:
        'You can download a CSV file containing your words and phrases before deleting it all from the app.',
    TEXT_DELETE_ALL_DATA_CONFIRMATION_3:
        'To prevent accidental deletion, please type "delete" in the box below and then press the red button to remove the data.',
    WORD_TYPE_OPTION_ADJECTIVE: 'adjective',
    WORD_TYPE_OPTION_ADVERB: 'adverb',
    WORD_TYPE_OPTION_CONJUNCTION: 'conjunction',
    WORD_TYPE_OPTION_GERUND: 'gerund',
    WORD_TYPE_OPTION_NOUN: 'noun',
    WORD_TYPE_OPTION_PREPOSITION: 'preposition',
    WORD_TYPE_OPTION_PRONOUN: 'pronoun',
    WORD_TYPE_OPTION_VERB: 'verb',
    WORD_TYPE_SELECT_LABEL: 'Word type',
}
