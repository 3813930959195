import React from 'react'
import styled from 'styled-components'

interface CardContainerProps {
    $size?: CardSize
    $bgColor?: string
    $bgImgUrl?: string
}

const CardContainer = styled.div<CardContainerProps>`
    padding: 3vw;
    background-color: ${(props) => props.$bgColor || 'white'};
    background-image: ${(props) => props.$bgImgUrl ? `url('${props.$bgImgUrl}')` : 'none'};
    background-size: 41%;
    background-position: -4vw -2vw;
    background-repeat: no-repeat;
    border-radius: 3vw;
    width: ${(props) => {
        switch (props.$size) {
            case CardSize.HALF:
                return '47.5%'
            case CardSize.FULL:
                return '100%'
            default:
                return 'auto'
        }
    }};
`

const CardTitle = styled.div`
    font-size: 3.5vw;
    color: #1a1a1a;
    font-weight: bold;
    margin-bottom: 4vw;
`

export enum CardSize {
    HALF = 'half',
    FULL = 'full',
    AUTO = 'auto',
}

export interface CardProps {
    children?: React.ReactNode | null
    size?: CardSize
    title?: string
    onClick?: () => void
    color?: string
    backgroundColor?: string
    backgroundImageUrl?: string
}

export const Card = (props: CardProps) => {
    const size = props.size || CardSize.AUTO
    return (
        <CardContainer
            onClick={props.onClick}
            $size={size}
            $bgColor={props.backgroundColor}
            $bgImgUrl={props.backgroundImageUrl}
        >
            {props.title && <CardTitle>{props.title}</CardTitle>}
            {props.children}
        </CardContainer>
    )
}
