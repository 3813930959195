import React, { useContext } from 'react';
import styled from 'styled-components';
import { ActiveOverlay } from '../App';
import { Card, CardSize } from '../layout/Card';
import { AppContext } from '../RootComponent';

const ProgressCardContainer = styled.div`
    background-image: url('/src/image/progress-graph.png');
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    height: 12vh;
`
export const ProgressCard = () => {
    const context = useContext(AppContext)
    return (
        <>
            <Card
                onClick={() => context.setActiveOverlay(ActiveOverlay.PROGRESS)}
                title=""
                size={CardSize.FULL}
            >
                <ProgressCardContainer />
            </Card>
        </>
    )
}
