import React from 'react'
import styled from 'styled-components'
import { ActiveOverlay } from '../App';
import { Card, CardSize } from '../layout/Card'
import { CardRow } from '../layout/CardRow'
import { AppContext } from '../RootComponent'

interface RewardIconProps {
    $score: number
}
const RewardIcon = styled.div<RewardIconProps>`
    background-image: url('/src/image/Icon STAR.svg');
    background-size: contain;
    background-repeat: no-repeat;
    width: ${props => fontSizeFromScore(props.$score)};
    height: ${props => fontSizeFromScore(props.$score)};
    display: inline-block;
    margin-right: 1vw !important;
    margin-bottom: 0 !important;
`
interface ScoreProps {
    $score: number
}

const fontSizeFromScore = (score: number) => {
    switch (true) {
        case score > 99999:
            return '8vw'
        case score > 9999:
            return '9vw'
        case score > 999:
            return '11vw'
        case score > 99:
            return '12vw'
        case score > 9:
            return '13vw'
        default:
            return '14vw'
    }
}

const Score = styled.div<ScoreProps>`
    font-size: ${props => fontSizeFromScore(props.$score)};
    font-weight: bold;
    color: #fcb64a;
    display: inline-block;
    text-align: center;
    &::before {
        content: "${props => props.$score}";
    }
`

export const PointsCard = () => {
    const context = React.useContext(AppContext)
    const score = context.loggedInUser?.rewardPoints || 0
    return (
        <Card
            onClick={() => context.setActiveOverlay(ActiveOverlay.POINTS)}
            title="Reward Points"
            size={CardSize.HALF}
        >
            <CardRow>
                <RewardIcon $score={score} />
                <Score $score={score} />
            </CardRow>
        </Card>
    )
}
