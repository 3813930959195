import LocalStorage from './LocalStorage'

interface GoogleAuthResponse {
    credential: string
    select_by: string
    client_id: string
}

export class GoogleAuth {
    public static async signOut(): Promise<void> {
        await window.google.accounts.id.disableAutoSelect()
        LocalStorage.clearCredential()
    }

    public static async signIn(
        element: HTMLDivElement
    ): Promise<GoogleAuthResponse> {
        return new Promise((resolve) => {
            window.google.accounts.id.initialize({
                client_id: process.env.GOOGLE_AUTH_CLIENT_ID,
                use_fedcm_for_prompt: true,
                auto_select: false,
                callback: (response: GoogleAuthResponse) => {
                    LocalStorage.setCredential(response.credential)
                    resolve(response)
                    // TODO - handle error
                },
            })
            window.google.accounts.id.renderButton(element, {
                theme: 'outline',
                size: 'large',
                text: 'login_with',
                shape: 'rectangular',
                locale: 'en',
            })
            window.google.accounts.id.prompt()
        })
    }
}
