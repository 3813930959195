import { getMysqlDateIgnoreTZ, dateDifferenceInDays } from 'czapp-shared'
import React, { useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'
import styled, { keyframes } from 'styled-components'
import { useDictionary } from '../../dictionary/useDictionary'
import { FullPageOverlay } from '../layout/FullPageOverlay'
import { CUSTOM_BUTTON_LABEL } from '../generic/CustomButton'
import { Logo } from '../generic/Logo'
import { AppContext } from '../RootComponent'

const TableBody = styled.tbody`
    height: unset;
`
const TableRow = styled.tr`
    border-bottom: 1px solid #8c8c8c;
    width: 90vw;
    &:first-child {
        border-top: 1px solid #8c8c8c;
        margin-top: 10vh;
    }
`
const TableHeaderCell = styled.th`
    padding: 2vh 5vw;
    width: 60%;
    text-align: right;
`
const TableCell = styled.td`
    padding: 2vh 5vw;
    border-bottom: none;
`
const TestButton = styled.button`
    padding: 4vw 6vw;
    margin-top: 15vh;
    font-size: 8vw;
    border: solid #9dd790 1vw;

    &:hover {
        background-color: #6e6e6e;
    }

    animation: ${keyframes`
        0%, 100% {
            background-color: #35ab19;
            color: #1c3a04;
        }
        50% {
            background-color: #1f4605;
            color: #aed0ae;
        }
    `} 3s infinite;
`

export const StreakReminder = () => {
    const context = useContext(AppContext)
    const dictionary = useDictionary()
    const [missedDays, setMissedDays] = React.useState<number>(0)

    useEffect(() => {
        if (context.loggedInUser?.currentStreakLength === 0) {
            const missedDays =
                dateDifferenceInDays(
                    context.loggedInUser.currentStreakEnd!,
                    getMysqlDateIgnoreTZ(new Date())!
                ) - 1
            setMissedDays(missedDays)
        }
    }, [context.loggedInUser?.currentStreakEnd])

    const onClose = () => {
        context.setShowStreakReminderOverlay(false)
    }

    return (
        <>
            <FullPageOverlay
                onClose={onClose}
                closeButtonLabel={CUSTOM_BUTTON_LABEL.close}
            >
                <Logo />
                <table>
                    <TableBody>
                        {missedDays > 0 && (
                            <TableRow>
                                <TableHeaderCell>
                                    {dictionary.STREAK_MISSED_DAYS}
                                </TableHeaderCell>
                                <TableCell>{missedDays}</TableCell>
                            </TableRow>
                        )}
                        {missedDays === 0 && (
                            <TableRow>
                                <TableHeaderCell>
                                    {dictionary.STREAK_CURRENT}
                                </TableHeaderCell>
                                <TableCell>
                                    {context.loggedInUser?.currentStreakLength}
                                </TableCell>
                            </TableRow>
                        )}
                        <TableRow>
                            <TableHeaderCell>
                                {dictionary.STREAK_MAX}
                            </TableHeaderCell>
                            <TableCell>
                                {context.loggedInUser?.highStreakLength}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableHeaderCell>
                                {dictionary.TESTS_COMPLETED}
                            </TableHeaderCell>
                            <TableCell>
                                {context.loggedInUser?.testsCompletedCount}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </table>
                <Link to="/learn">
                    <TestButton>{dictionary.START_TEST_BUTTON}</TestButton>
                </Link>
            </FullPageOverlay>
        </>
    )
}
