import { AppError } from 'czapp-shared'
import { useContext, useRef } from 'react'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { useDictionary } from '../dictionary/useDictionary'
import { Api } from '../util/Api'
import { GoogleAuth } from '../util/GoogleAuth'
import { ActiveOverlay } from './App';
import { AppContext, TopLevelPage } from './RootComponent'

const StyledParentDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
`
const SignInText = styled.div`
    font-size: 4vw;
    margin-top: 5vh;
`
const AppTitle = styled.div`
    font-size: 3em;
    margin: 2em 1em;
`
const SubtitleText = styled.div`
    font-size: 1.5em;
    margin-bottom: 7em;
    text-align: center;
`
const RememberMeCheckbox = styled.div`
    margin-bottom: 1em;
`

export const SignInPage = () => {
    const context = useContext(AppContext)
    const dictionary = useDictionary()
    const [isRememberMeChecked, setIsRememberMeChecked] = React.useState(false)
    const gAuthButtonRef = useRef<HTMLDivElement>(document.createElement('div'))
    const navigate = useNavigate()

    React.useEffect(() => {
        if (process.env.DEV_MODE === 'true') return
        GoogleAuth.signIn(gAuthButtonRef.current)
            .then(async (signInResponse) => {
                if (signInResponse.credential) {
                    try {
                        const { data: userData } =
                            await Api.signIn(isRememberMeChecked)
                        if (!userData) {
                            context.setAppError(AppError.REQUEST_SIGN_IN_FAILED)
                        }
                        context.setLoggedInUser(userData)
                        context.setActiveTopLevelPage(TopLevelPage.HOME)
                        context.setActiveOverlay(ActiveOverlay.NONE)
                        // navigate('/', { replace: true })
                    } catch {
                        context.setAppError(AppError.REQUEST_SIGN_IN_FAILED)
                    }
                }
            })
            .catch((response: unknown) => console.log(response))
    }, [isRememberMeChecked])

    return (
        <StyledParentDiv className="page signInPage">
            <AppTitle>{dictionary.APP_TITLE}</AppTitle>
            <SubtitleText>{dictionary.APP_SUBTITLE}</SubtitleText>
            <RememberMeCheckbox>
                <input
                    type="checkbox"
                    onChange={(event) => {
                        setIsRememberMeChecked(event.target.checked)
                    }}
                />
                {dictionary.CHECKBOX_REMEMBER_ME}
            </RememberMeCheckbox>
            <div id="googleSignInButton" ref={gAuthButtonRef}></div>
            <SignInText className="appSubtitle">
                {dictionary.APP_SUBTITLE_2}
            </SignInText>
        </StyledParentDiv>
    )
}
