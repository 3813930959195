import { Language } from 'czapp-shared'
import React from 'react'
import { InputArea } from './InputArea'
import { LanguageIdentifier } from './LanguageIdentifier'

export interface InputWithLanguageFlagProps {
    inputText: string
    inputLanguage: Language
    onInputTextChanged: (event: React.ChangeEvent<HTMLTextAreaElement>) => void
    onClearInputTextButtonClicked: () => void
    onKeydown?: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void
    placeholder?: string
    autoFocus?: boolean
}

export const InputWithLanguageFlag = (props: InputWithLanguageFlagProps) => {
    return (
        <>
            <LanguageIdentifier
                language={props.inputLanguage}
                $left={'3vw'}
                $top={'16vw'}
                $size={'12vw'}
            />
            <InputArea
                value={props.inputText}
                onChange={props.onInputTextChanged}
                onClear={props.onClearInputTextButtonClicked}
                onKeydown={props.onKeydown}
                placeholder={props.placeholder}
                autoFocus={props.autoFocus}
            />
        </>
    )
}
