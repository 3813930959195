import React from 'react'
import styled from 'styled-components'

const AlphaLogoElement = styled.div`
    background-image: url('/src/image/Icon LEVEL.svg');
    background-size: contain;
    background-repeat: no-repeat;
    width: 38vw;
    height: 28vw;
    content: '';
    margin: 5vw auto 5vw;
`

export const AlphaLogo = () => {
    return <AlphaLogoElement />
}
