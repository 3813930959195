import {
    ILexeme,
    ILexemePair,
    ITestResult,
    IUserData, Language,
    TestCompleteResponseData,
    TestInitResponseData
} from 'czapp-shared';
import { TestStatus } from './VocabCheck';

export enum VcAction {
    INIT_FLASHCARDS = 'initFlashcards',
    INIT_TEST = 'initTest',
    REGISTER_RESULT = 'registerResult',
    SET_CURRENT_ANSWERS = 'setCurrentAnswers',
    UPLOAD_RESULTS = 'uploadResults',
    UNLOCK_TESTS = 'unlockTests',
    DISPLAY_RESULTS = 'displayResults',
    RESTART = 'restart'
}

interface VcState {
    lexemePairs: ILexemePair[]
    status: TestStatus
    currentIndex: number
    currentAnswers: ILexeme[]
    results: ITestResult[]
    userData: IUserData | undefined
    rewardPointsDelta: number
    testResultIds: number[]
}

type VcActions =  {
    type: VcAction.INIT_FLASHCARDS,
    payload: TestInitResponseData
} | {
    type: VcAction.INIT_TEST,
    payload: TestInitResponseData
} | {
    type: VcAction.REGISTER_RESULT,
    payload: ITestResult
} | {
    type: VcAction.SET_CURRENT_ANSWERS,
    payload: ILexeme[]
} | {
    type: VcAction.UPLOAD_RESULTS,
    payload: ITestResult[]
} | {
    type: VcAction.DISPLAY_RESULTS,
    payload: TestCompleteResponseData
} | {
    type: VcAction.RESTART
}

export const vocabCheckReducer = (state: VcState, action: VcActions) => {

    let newStatus: TestStatus

    switch (action.type) {

        case VcAction.INIT_FLASHCARDS:
            console.log('initializing flashcards');

            const {
                lexemePairs,
                lexemeLearningInfo: {
                    learningCount,
                    retiredCount
                }
            } = action.payload

            newStatus =
                lexemePairs.length > 0
                    ? TestStatus.SHOW_QUESTION
                    : learningCount > 0
                        ? TestStatus.NO_CARDS_SELECTED
                        : retiredCount > 0
                            ? TestStatus.ALL_RETIRED
                            : TestStatus.EMPTY_DICTIONARY;

            return {
                ...state,
                status: newStatus,
                lexemePairs: action.payload.lexemePairs
            }

        case VcAction.INIT_TEST:
            console.log('initializing test');

            newStatus = action.payload.lexemePairs.length > 0
                ? TestStatus.SHOW_QUESTION
                : TestStatus.EMPTY_DICTIONARY

            return {
                ...state,
                status: newStatus,
                lexemePairs: action.payload.lexemePairs
            }

        case VcAction.REGISTER_RESULT:
            let newIndex = state.currentIndex;
            if (state.currentIndex < state.lexemePairs.length - 1) {
                newStatus = TestStatus.SHOW_QUESTION
                newIndex = state.currentIndex + 1
            } else {
                newStatus = TestStatus.ENDED
            }
            return {
                ...state,
                currentIndex: newIndex,
                status: newStatus,
                results: [...state.results, action.payload]
            }

        case VcAction.SET_CURRENT_ANSWERS:
            console.log('setting current answers');
            return {
                ...state,
                status: TestStatus.SHOW_QUESTION,
                currentAnswers: action.payload
            }

        case VcAction.UPLOAD_RESULTS:
            console.log('uploading results');
            return {
                ...state,
                results: action.payload
            }

        case VcAction.DISPLAY_RESULTS:
            console.log('displaying results');
            return {
                ...state,
                userData: action.payload.user,
                rewardPointsDelta: action.payload.rewardPointsDelta,
                testResultIds: action.payload.testResultIds,
                status: TestStatus.ENDED
            }

        case VcAction.RESTART:
            console.log('restarting');
            return {
                ...state,
                status: TestStatus.NOT_STARTED,
                currentIndex: 0,
                currentAnswers: [],
                results: [],
                rewardPointsDelta: 0,
                testResultIds: []
            }
        default:
            return state
    }
}
