import { Familiarity, Progress } from 'czapp-shared';

// export type GradeInfo = [Familiarity, number | undefined]

export type GradeData = {
    reference: string
    attempt: string
    familiarity: Familiarity
    distance: number
}

/**
 * @name minDisRec
 * @description Recursive function to find minimum edit distance between two strings
 * @note This function is not meant to be called directly. Use minDis instead.
 * @url https://www.geeksforgeeks.org/edit-distance-dp-5/
 * @url https://en.wikipedia.org/wiki/Edit_distance
 * @param s1 first string
 * @param s2 second string
 * @param m length of S1 string
 * @param n length of S2 string
 * @param memo memoization table
 * @returns number
 */
const minDisRec = (
    s1: string,
    s2: string,
    m: number,
    n: number,
    memo: number[][]
): number => {
    if (m === 0) return n
    if (n === 0) return m
    if (memo[m][n] !== -1) return memo[m][n]
    if (s1[m - 1].toLowerCase() === s2[n - 1].toLowerCase()) {
        memo[m][n] = minDisRec(s1, s2, m - 1, n - 1, memo)
    } else {
        const insert = minDisRec(s1, s2, m, n - 1, memo) // Insert
        const remove = minDisRec(s1, s2, m - 1, n, memo) // Remove
        const replace = minDisRec(s1, s2, m - 1, n - 1, memo) // Replace
        memo[m][n] = 1 + Math.min(insert, remove, replace)
    }
    return memo[m][n]
}

/**
 * @name minDis
 * @description Function to initialize memoization table and start the recursive function
 * @param reference the "correct" string (retrieved from the database)
 * @param attempt the text entered by the user
 */
export const minDis = (reference: string, attempt: string): number => {
    const m = reference.length,
        n = attempt.length
    const memo = Array.from({ length: m + 1 }, () => Array(n + 1).fill(-1))
    return minDisRec(reference, attempt, m, n, memo)
}

export const removeMultipleWhitespaces = (string: string): string => string.replace(/\s+/g, ' ')

/**
 * @name gradeAnswer
 *
 * @description Function to grade the user's answer. If edit distance is more than 25% of the length of the reference
 * string, the answer is considered incorrect. If the edit distance is less than or equal to 25% of the length of the
 * reference string, the answer is considered almost correct. If the edit distance is 0, the answer is correct.
 *
 * @param reference the "correct" string (retrieved from the database) - this may contain multiple possibilities
 *   separated by '|' characters
 * @param attempt the text entered by the user
 * @param leniency the threshold for the edit distance to be considered incorrect. This is the calculated edit distance
 *   as a percentage of the length of the reference string.
 *
 * @returns [Grade, distance]
 * @example
 * gradeAnswer('noon', 'noon') // 'correct'
 * gradeAnswer('noon', 'non') // 'almost'
 * gradeAnswer('noon', 'nooon') // 'almost'
 * gradeAnswer('noon', 'book') // 'incorrect'
 */
export const gradeAnswer = (
    reference: string,
    attempt: string,
    leniency: number
): GradeData => {

    attempt = attempt.trim()
    attempt = removeMultipleWhitespaces(attempt)

    // Grade the attempt against each possible answer
    const grades: GradeData[] = []

    const referenceAnswers = reference.split('|')

    for (let i = 0; i < referenceAnswers.length; i++) {
        referenceAnswers[i] = referenceAnswers[i].trim()
        referenceAnswers[i] = removeMultipleWhitespaces(referenceAnswers[i])
        grades.push(gradeSingleAnswer(referenceAnswers[i], attempt, leniency))
    }
    return getBestGrade(grades)
}

export const getBestGrade = (grades: GradeData[]): GradeData => {

    // Set the best grade to the first grade
    let bestGrade =  { ...grades[0] }

    // if only one grade then return it already
    if (grades.length === 1) return bestGrade

    // if there are multiple grades from multiple possible answers, then try to improve on the best grade
    grades.forEach((grade) => {

        switch (grade.familiarity) {

            case Familiarity.UNKNOWN:
                switch (bestGrade.familiarity) {
                    case Familiarity.UNKNOWN:
                        if (grade.distance < bestGrade.distance) bestGrade = {...grade}
                        break
                    case Familiarity.ALMOST:
                    case Familiarity.KNOWN:
                        break
                }
                break

            case Familiarity.ALMOST:
                switch (bestGrade.familiarity) {
                    case Familiarity.UNKNOWN:
                        bestGrade = {...grade}
                        break
                    case Familiarity.ALMOST:
                        if (grade.distance < bestGrade.distance) bestGrade = {...grade}
                        break
                    case Familiarity.KNOWN:
                        break
                }
                break

            case Familiarity.KNOWN:
                switch (bestGrade.familiarity) {
                    case Familiarity.UNKNOWN:
                    case Familiarity.ALMOST:
                        bestGrade = {...grade}
                        break
                    case Familiarity.KNOWN:
                        if (grade.distance < bestGrade.distance) bestGrade = {...grade}
                        break
                }
                break
        }
    })

    return bestGrade
}

export const gradeSingleAnswer = (reference: string, attempt: string, leniency: number): GradeData => {
    const distance = minDis(reference, attempt)

    const output = {
        reference,
        attempt,
        familiarity: Familiarity.KNOWN,
        distance
    }

    if (distance !== 0) {
        output.familiarity = distance <= Math.floor(reference.length * (leniency / 100))
            ? Familiarity.ALMOST
            : Familiarity.UNKNOWN
    }

    return output
}

export const hasImproved = (before: Familiarity, after: Familiarity): Progress => {
    switch (before) {
        case Familiarity.UNKNOWN:
            return after !== Familiarity.UNKNOWN
                ? Progress.IMPROVED
                : Progress.NONE

        case Familiarity.ALMOST:
            switch (after) {
                case Familiarity.UNKNOWN:
                    return Progress.WORSE
                case Familiarity.ALMOST:
                    return Progress.NONE
                default:
                    return Progress.IMPROVED
            }

        default:
            return after !== Familiarity.KNOWN
                ? Progress.WORSE
                : Progress.NONE
    }
}

export const processThinkingTime = (thinkingStartTime: number): number => {
    // Re-format thinking time from the high precision timer into a format of seconds to two decimal places
    let thinkingTimeSecs =
        Math.round((performance.now() - thinkingStartTime!) / 10) / 100

    // Only 5 digits can be stored in the MySQL DECIMAL (5,2) field, so we need to limit the thinking time to 999.99
    return Math.min(999.99, thinkingTimeSecs)
}
