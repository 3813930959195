import {
    ApiMethod,
    ApiPath,
    DeeplTranslation,
    Familiarity,
    HttpStatusCode,
    ILexeme,
    ILexemePair,
    Language,
    parameterReplace,
    IUserData,
    VocabCheckType,
    TestInitResponseData,
    InsertOrUpdateRequestBody,
    TestCompleteRequestBody,
    AutologinRequestBody,
    TestCompleteResponseData,
    InsertOrUpdateResponseData,
} from 'czapp-shared'
import { getMysqlDateIgnoreTZ } from 'czapp-shared'
import { RowDataPacket } from 'mysql2'
import LoaderUtil from './LoaderUtil'
import { Logger } from './Logger'

export const devModeUserData: IUserData = {
    currentStreakEnd: getMysqlDateIgnoreTZ(new Date())!,
    currentStreakLength: 45,
    email: 'dev@mode.user',
    googleSub: 'dev-mode-user',
    highStreakEnd: '2021-01-01 00:00:00',
    highStreakLength: 199,
    lastModified: '2024-08-25 14:34:40',
    lastWordAdded: 'Anti-disestablishmentarianism',
    learningLanguage: Language.CZECH,
    lexemePairCount: 123,
    name: 'Dev Mode User',
    phone: '123456789',
    rewardPoints: 12599,
    signUpDate: '2024-08-25 14:34:40',
    testsCompletedCount: 45,
    flashcardReviewCompletedCount: 54,
    uiLanguage: Language.ENGLISH,
}

// export type StreakData = Pick<
//     IUserData,
//     "currentStreakLength" |
//     "currentStreakEnd" |
//     "highStreakLength" |
//     "highStreakEnd" |
//     "testsCompletedCount"
// >;

/**
 * This class is a facade for the backend API.
 * It is responsible for making requests to the backend and parsing the responses.
 * It is also responsible for handling errors and logging exceptions.
 */
export class Api {
    static autologin = async (body: AutologinRequestBody) => {
        const path = ApiPath.AUTOLOGIN
        const method = ApiMethod.POST
        return await LoaderUtil.request<IUserData>(path, method, body)
    }

    static signIn = async (rememberMe: boolean) => {
        const path = ApiPath.SIGN_IN
        const method = ApiMethod.POST
        return await LoaderUtil.request<IUserData>(path, method, { rememberMe })
    }

    static signOut = async () => {
        const path = ApiPath.SIGN_OUT
        const method = ApiMethod.GET
        return await LoaderUtil.request<boolean>(path, method)
    }

    static _fetchAndParse = async <T>(
        path: string,
        method: ApiMethod,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        parser: (data: any) => T,
        body?: object
    ) => {
        const result = await LoaderUtil.request<T[]>(path, method, body)
        if (result.status !== HttpStatusCode.OK) {
            Logger.exception(
                new Error(`[Api.callApiForMysqlResponse]: ${result.message}`)
            )
            throw new Error(`[Api.callApiForMysqlResponse]: ${result.message}`)
        }
        return parser(result.data)
    }

    static getLexemes = async (page: number, length: number) =>
        await LoaderUtil.request<ILexemePair[]>(
            `${ApiPath.LEXEMES}/${length}/${page}`,
            ApiMethod.GET
        )

    static getVocabCheckLexemes = async (vocabCheckType: VocabCheckType) =>
        await LoaderUtil.request<TestInitResponseData>(
            ApiPath.TEST_INIT,
            ApiMethod.POST,
            { vocabCheckType }
        )

    static getAllTranslations = async (
        sourceText: string,
        sourceLang: Language
    ) =>
        await LoaderUtil.request<ILexeme[]>(
            parameterReplace(ApiPath.TRANSLATIONS_ALL, [
                encodeURIComponent(sourceText),
                encodeURIComponent(sourceLang),
            ]),
            ApiMethod.GET
        )

    static deleteLexemePair = async (czId: number, enId: number) =>
        await Api._fetchAndParse<string>(
            parameterReplace(ApiPath.LEXEME_PAIR, [
                encodeURIComponent(czId),
                encodeURIComponent(enId),
            ]),
            ApiMethod.DELETE,
            // TODO - provide a better parser for the response
            (message: string) => message
        )

    static putLexemePairFamiliarity = async (
        czId: number,
        enId: number,
        familiarity: Familiarity
    ) =>
        await LoaderUtil.request<RowDataPacket[]>(
            parameterReplace(ApiPath.LEXEME_PAIR_FAMILIARITY, [
                encodeURIComponent(czId),
                encodeURIComponent(enId),
                encodeURIComponent(familiarity),
            ]),
            ApiMethod.PUT
        )

    static postLexemePair = async (payload: InsertOrUpdateRequestBody) =>
        await Api._fetchAndParse<InsertOrUpdateResponseData>(
            ApiPath.LEXEMES,
            ApiMethod.POST,
            // TODO - provide a better parser for the response
            (data: InsertOrUpdateResponseData) => data,
            payload
        )

    static getUserData = async () => {
        const response = await LoaderUtil.request<IUserData[]>(ApiPath.USER, ApiMethod.GET)
        return response.data[0]
    }

    static deleteAllUserData = async () =>
        await LoaderUtil.request<void>(
            ApiPath.LEXEMES_DELETE_ALL,
            ApiMethod.DELETE
        )

    static postTranslation = async (
        inputText: string,
        source: Language,
        target: Language
    ) => {
        const result = await LoaderUtil.request<DeeplTranslation>(
            ApiPath.TRANSLATE,
            ApiMethod.POST,
            {
                q: encodeURIComponent(inputText),
                source,
                target,
                format: 'text',
            }
        )
        return result.data.translations[0].translatedText
    }

    static postUser = async (
        learningLanguage: Language,
        uiLanguage: Language
    ) =>
        await LoaderUtil.request<void>(ApiPath.USER, ApiMethod.POST, {
            learningLanguage,
            uiLanguage,
        })

    static postTestComplete = async (body: TestCompleteRequestBody) =>
        await LoaderUtil.request<TestCompleteResponseData>(
            ApiPath.TEST_COMPLETE,
            ApiMethod.POST,
            body
        )

    static skipDeck = async () => {
        return await LoaderUtil.request<boolean>(ApiPath.TEST_SKIP_DECK, ApiMethod.GET)
    }
}
