import React, { useContext } from 'react'
import styled from 'styled-components'
import { ActiveOverlay } from './App';
import { BigButton, navBgColor, NavButtonNu, NavButtonType, } from './generic/NavButtonNu'
import { AppContext } from './RootComponent';

const NavigationBar = styled.div`
    display: flex;
    padding: 1.3vh;
    position: absolute;
    bottom: 0;
    height: 20vw;
    width: 100vw;
    justify-content: space-around;
    align-items: baseline;
    background-color: ${navBgColor};
`

export const Navigation = () => {
    const context = useContext(AppContext)
    const flashcardButtonLeft = '0'
    const addButtonLeft = '70vw'
    return (
        <NavigationBar className="navigation">

            <BigButton
                left={flashcardButtonLeft}
                iconUrl="/src/image/Icon Flashcard.svg"
                onClick={() => {
                    context.setActiveOverlay(ActiveOverlay.FLASHCARDS)
                }}
                backgroundColor="#ffb74b"
                backgroundSize="10vw"
            />

            {/*<NavButtonNu onClick={() => {*/}
            {/*    context.setActiveOverlay(ActiveOverlay.NONE)*/}
            {/*}} type={NavButtonType.HOME} />*/}

            <BigButton
                left={addButtonLeft}
                iconUrl="/src/image/Icon PLUS.svg"
                onClick={() => {
                context.setActiveOverlay(ActiveOverlay.ADD)
            }} />

        </NavigationBar>
    )
}
