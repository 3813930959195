import React from 'react'
import styled from 'styled-components'
import { CardProps } from './Card'

const CardRowContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: stretch;
    > div {
        margin-right: 4vw;
        margin-bottom: 4vw;
    }
    > div:last-child {
        margin-right: 0;
    }
`

export const CardRow = (props: CardProps) => {
    return <CardRowContainer>{props.children}</CardRowContainer>
}
