import React, { useContext } from 'react'
import styled from 'styled-components'
import { ActiveOverlay } from '../App';
import { CUSTOM_BUTTON_LABEL, CustomButton } from '../generic/CustomButton'
import { Card } from '../layout/Card'
import { AppContext } from '../RootComponent';

interface UnlockedTestCardContainerProps {
    $hidden: boolean
}
const UnlockedTestCardContainer = styled.div<UnlockedTestCardContainerProps>`
    margin-bottom: 4vw;
    text-align: center;
    overflow: hidden;
    max-height: ${(props) => (props.$hidden ? '0' : '60vw')};
    transition: max-height 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    
`

const UnlockedText = styled.div`
    font-size: 6vw;
    margin-top: 2vw;
    font-weight: bold;
    color: #686868;
    em {
        font-size: 8vw;
        color: white;
    }
    b {
        font-size: 7vw;
    }
`

const TestEnrollButton = styled.button`
    color: #80bda6;
    background-color: white;
    font-size: 7vw;
    font-weight: bold;
    padding: 5vw 8vw;
    border: none;
    border-radius: 20vw;
    margin: 3vw;
`

interface UnlockedTestCardProps {
    setShowTestNotification: (show: boolean) => void
    hidden: boolean
}

export const UnlockedTestCard = (props: UnlockedTestCardProps) => {
    const context = useContext(AppContext)
    return (
        <UnlockedTestCardContainer $hidden={props.hidden}>
            <Card backgroundColor="#80bda6;">
                <CustomButton
                    label={CUSTOM_BUTTON_LABEL.close}
                    onClick={() => props.setShowTestNotification(false)}
                    floatRight={true}
                    size="5vw"
                />
                <UnlockedText>
                    A new <em>TEST</em> is <b>unlocked!</b>
                </UnlockedText>
                <TestEnrollButton onClick={() => {
                    context.setActiveOverlay(ActiveOverlay.TEST)
                }}>
                    ENROLL NOW!
                </TestEnrollButton>
            </Card>
        </UnlockedTestCardContainer>
    )
}
