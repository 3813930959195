import { AppError, ILexemePair } from 'czapp-shared'
import * as React from 'react'
import { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDictionary } from '../../dictionary/useDictionary'
import { Api } from '../../util/Api'
import { EmptyDictionary } from '../generic/EmptyDictionary'
import { AppContext } from '../RootComponent'
import { LexemePairRow } from '../subcomponent/LexemePairRow'

const DEFAULT_PAGE = 1
const DEFAULT_PAGE_LENGTH = 50

const RecentPageHeading = styled.h1`
    color: #75b1cb;
    margin-bottom: 10vw;
    font-size: 9vw;
`

export const Recents = () => {
    const context = useContext(AppContext)
    const [data, setData] = useState<ILexemePair[]>([])
    const [page, setPage] = useState<number>(DEFAULT_PAGE)
    const [isLoadingNextPage, setIsLoadingNextPage] = useState<boolean>(false)
    const [endOfData, setEndOfData] = useState<boolean>(false)

    const addButtonRef = React.createRef<HTMLButtonElement>()
    const dictionary = useDictionary()

    useEffect(() => {
        ;(async () => {
            await loadLexemePairs()
        })()
    }, [])

    useEffect(() => {
        if (addButtonRef.current) addButtonRef.current.focus()
    }, [data])

    const onBodyScrolled = async (
        e: React.UIEvent<HTMLTableSectionElement>
    ) => {
        const body = e.currentTarget
        if (body.scrollTop + body.clientHeight >= body.scrollHeight) {
            if (!isLoadingNextPage && !endOfData) {
                setIsLoadingNextPage(true)
                await loadLexemePairs()
                setIsLoadingNextPage(false)
            }
        }
    }

    const loadLexemePairs = async () => {
        try {
            const { data: newData } = await Api.getLexemes(
                page,
                DEFAULT_PAGE_LENGTH
            )
            setPage(page + 1)
            const concatenatedData = data ? data.concat(newData) : newData
            setData(concatenatedData)
            if (data.length < DEFAULT_PAGE_LENGTH) {
                setEndOfData(true)
            }
        } catch {
            context.setAppError(AppError.REQUEST_GET_LEXEMES_FAILED)
        }
    }

    if (!data) return null

    return (
        <>
            <RecentPageHeading>{dictionary.PAGETITLE_RECENT}</RecentPageHeading>
            {data.length > 0 && (
                <table className="recentsTable">
                    <tbody onScroll={onBodyScrolled}>
                        {data.map((pair, index) => (
                            <LexemePairRow data={pair} key={index} />
                        ))}
                    </tbody>
                </table>
            )}
            {data.length === 0 && <EmptyDictionary />}
        </>
    )
}
