import { Familiarity, ITestResult, Language, Progress } from 'czapp-shared'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom';
import styled, { keyframes } from 'styled-components'
import { useDictionary } from '../../dictionary/useDictionary'
import { hasImproved, processThinkingTime } from '../../util/Grade';
import { LanguageIdentifier } from '../generic/LanguageIdentifier'
import { QuestionProps } from '../overlay/VocabCheck'
import { AppContext } from '../RootComponent'
import { FamiliarityButtons } from './FamiliarityButtons';
import { Question } from './Question';
import { QuestionOrAnswerSection } from './QuestionOrAnswerSection';
import { TestProgressMeter } from './TestProgressMeter';

const pulsingRevealButton = keyframes`
    0%, 100% { 
        background-color: #d99a1c; 
        color: #463905;
    }
    50% { 
        background-color: #463905;
        color: #d99a1c;
    }
`
const RevealButton = styled.button`
    padding: 2vh 6vw;
    font-size: 4vh;
    color: #463905;
    margin: 10vh auto;
    display: block;
    animation: ${pulsingRevealButton} 3s infinite;
`

const FadeInText = styled.div`
    @keyframes fadeIn {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
    animation: fadeIn 3s;
    display: inline-block;
`

export const FlashCardQuestion = (props: QuestionProps) => {
    const context = useContext(AppContext)
    const dictionary = useDictionary()

    const [showButtonClicked, setShowButtonClicked] = useState(false)
    const thinkingStartTime = useRef<number | null>(null)

    useEffect(() => {
        thinkingStartTime.current = performance.now()
    }, [])

    const onFamiliaritySelected = async (familiarity: Familiarity) => {
        const czId = props.lexemePair.czechLexeme.id
        const enId = props.lexemePair.englishLexeme.id

        if (!czId || !enId) {
            return
        }

        // // Re-format thinking time from the high precision timer into a format of seconds to two decimal places
        // let thinkingTimeSecs =
        //     Math.round((performance.now() - thinkingStartTime.current!) / 10) / 100
        //
        // // Only 5 digits can be stored in the MySQL DECIMAL (5,2) field, so we need to limit the thinking time to 999.99
        // thinkingTimeSecs = Math.min(999.99, thinkingTimeSecs)

        const lexemePair = {
            ...props.lexemePair,
            familiarity,
        }

        const result = {
            lexemePair,
            progress: hasImproved(props.lexemePair.familiarity, familiarity),
            thinkingTimeSecs: processThinkingTime(thinkingStartTime.current!),
        } as ITestResult

        setShowButtonClicked(false)
        await props.onResult(result)
    }

    const renderLanguageIdentifier = (language: Language) => <LanguageIdentifier language={language} $left={'3vw'} $top={'6vw'} $size={'12vw'}/>

    const renderRevealButton = () => <RevealButton onClick={() => setShowButtonClicked(true)}>
        {dictionary.BUTTON_REVEAL_ANSWER}
    </RevealButton>

    const renderAnswerOrAnswers = (answerText: string) => {
        if (answerText.indexOf('|')  > 0) {
            let splitText = answerText.split('|')
            return <QuestionOrAnswerSection>
                {splitText.map((text, index) =>
                    <span key={index} className="multiple text">
                        <FadeInText><span className="index">{`${index + 1}.`}</span> {text}</FadeInText>
                    </span>)}
            </QuestionOrAnswerSection>
        }
        return <QuestionOrAnswerSection>
            <span className="text">
                <FadeInText>{answerText}</FadeInText>
            </span>
        </QuestionOrAnswerSection>
    }

    const renderHiddenSection = () => {
        const learningLanguage = context.learningLanguage
        const { lexemePair: { englishLexeme: { text: enText}, czechLexeme: { text: czText}}} = props
        const answerText = learningLanguage === Language.ENGLISH ? enText : czText
        const language = learningLanguage === Language.ENGLISH ? Language.ENGLISH : Language.CZECH
        return <>
            {renderLanguageIdentifier(language)}
            {renderAnswerOrAnswers(answerText)}
            {createPortal(
                <FamiliarityButtons onFamiliaritySelected={onFamiliaritySelected} />,
                document.body
            )}
        </>
    }

    return <>
        <TestProgressMeter
            currentQuestion={props.currentIndex + 1}
            totalQuestions={props.testLength}
        />
        <Question lexemePair={props.lexemePair} />
        {!showButtonClicked && renderRevealButton()}
        {showButtonClicked && renderHiddenSection()}
    </>
}
