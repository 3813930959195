import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
    position: relative;
`

const StyledTextArea = styled.textarea`
    width: 100%;
    height: 15vh;
    padding: 10vw 12vw 5vw 5vw;
    margin: 5vh 0 3.5vh;
    box-sizing: border-box;
    border-radius: 8px;
    text-align: center;
`

const ClearButton = styled.button`
    background-color: rgba(0, 0, 0, 0);
    border: none;
    cursor: pointer;
    top: 5.5vh;
    position: absolute;
    right: 0;
    font-size: 7vw;
`

export interface InputAreaProps {
    value: string
    onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void
    onKeydown?: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void
    autoFocus?: boolean
    onClick?: () => void
    placeholder?: string
    onClear?: () => void
}

export const InputArea = (props: InputAreaProps) => {
    const clear = () => {
        if (props.onClear) props.onClear()
    }

    return (
        <Container>
            {props.value !== '' && <ClearButton onClick={clear}>x</ClearButton>}
            <StyledTextArea
                value={props.value}
                placeholder={props.placeholder}
                onClick={props.onClick}
                onChange={props.onChange}
                onKeyDown={props.onKeydown}
            />
        </Container>
    )
}
