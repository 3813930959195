import React from 'react'
import styled from 'styled-components'

const FadeInContainer = styled.div`
    animation: fadeIn 1s;
    @keyframes fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
`

export const FadeIn: React.FC<React.PropsWithChildren<object>> = (props) => {
    return <FadeInContainer>{props.children}</FadeInContainer>
}
