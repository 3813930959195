import { Familiarity } from 'czapp-shared';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useDictionary } from '../../dictionary/useDictionary';

interface FamiliarityButtonProps {
    $familiarity: Familiarity
}
const FamiliarityButton = styled.button<FamiliarityButtonProps>`
    font-size: 10vh;
    border-radius: 15vw;
    margin: 5vw;
    color: white;
    background-color: ${(props) => {
    switch (props.$familiarity) {
        case Familiarity.UNKNOWN:
            return '#f44336'
        case Familiarity.ALMOST:
            return '#ff9800'
        case Familiarity.KNOWN:
            return '#00c508'
        default:
            return '#f44336'
    }
}};
`
interface FamiliarityButtonsContainerProps {
    $hidden: boolean
}

const FamiliarityButtonsContainer = styled.div<FamiliarityButtonsContainerProps>`
    flex-direction: row;
    flex: 1;
    text-align: center;
    position: relative;
    top: ${(props) => props.$hidden ? '5vh' : '-10vh'};
    opacity: ${(props) => props.$hidden ? '0' : '1'};
    transition: all .3s cubic-bezier(0.19, 1, 0.22, 1);
    button {
        &.unknown {
            background-color: #980101;
        }

        &.familiar {
            background-color: #936300;
        }
        &.known {
            background-color: green;
        }
    }
`

export interface FamiliarityButtonsProps {
    onFamiliaritySelected: (familiarity: Familiarity) => void
}

export const FamiliarityButtons = (props: FamiliarityButtonsProps) => {

    const dictionary = useDictionary()
    const [hidden, setHidden] = React.useState(true)
    useEffect(() => {
        setHidden(false)
    }, [])

    const onFamiliaritySelected = async (familiarity: Familiarity) => {
        setHidden(true)
        setTimeout(() => {
            props.onFamiliaritySelected(familiarity)
        }, 300)
    }

    return (
        <FamiliarityButtonsContainer $hidden={hidden}>

            <FamiliarityButton
                className={`customButton  material-symbols-outlined familiarity ${Familiarity.KNOWN}`}
                onClick={() => onFamiliaritySelected(Familiarity.KNOWN)}
                title={dictionary.BUTTON_RATE_AS_KNOWN}
                $familiarity={Familiarity.KNOWN}
            >sentiment_satisfied</FamiliarityButton>

            <FamiliarityButton
                className={`customButton  material-symbols-outlined familiarity ${Familiarity.UNKNOWN}`}
                onClick={() => onFamiliaritySelected(Familiarity.UNKNOWN)}
                title={dictionary.BUTTON_RATE_AS_UNKNOWN}
                $familiarity={Familiarity.UNKNOWN}
            >sentiment_dissatisfied</FamiliarityButton>

        </FamiliarityButtonsContainer>
    )
}
