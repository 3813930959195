import * as React from 'react'
import { useDictionary } from '../../dictionary/useDictionary'

export const EmptyDictionary = () => {
    const dictionary = useDictionary()
    return (
        <div className="emptyDictionaryAlert">
            <div className="middleContainer">
                <div className="message">
                    {dictionary.MESSAGE_DICTIONARY_EMPTY}
                </div>
            </div>
        </div>
    )
}
