import { ITestResult, Language } from 'czapp-shared';
import React, { useContext, useEffect } from 'react'
import { createPortal } from 'react-dom';
import { gradeAnswer, hasImproved, processThinkingTime } from '../../util/Grade';
import { InputWithLanguageFlag } from '../generic/InputWithlanguageFlag';
import { QuestionProps } from '../overlay/VocabCheck';
import { AppContext } from '../RootComponent';
import { Question } from './Question';
import { ResultToast } from './ResultToast';
import { TestProgressMeter } from './TestProgressMeter';

const GRADING_LENIENCY_PERCENTAGE = 25

export const TestQuestion = (props: QuestionProps) => {

    const context = useContext(AppContext)
    const [answer, setAnswer] = React.useState('')
    const [, setQuestion] = React.useState('')
    const [displayToast, setDisplayToast] = React.useState(false)
    const [referenceAnswer, setReferenceAnswer] = React.useState('')
    const [result, setResult] = React.useState<ITestResult | null>(null)
    const thinkingStartTime = React.useRef<number>(0)

    useEffect(() => {
        setQuestion(
            context.loggedInUser?.learningLanguage === Language.CZECH
                ? props.lexemePair.englishLexeme.text
                : props.lexemePair.czechLexeme.text
        )
        setReferenceAnswer(
            context.loggedInUser?.learningLanguage === Language.CZECH
                ? props.lexemePair.czechLexeme.text
                : props.lexemePair.englishLexeme.text
        )
        setAnswer('')
        thinkingStartTime.current = performance.now()
    }, [props.currentIndex]);

    useEffect(() => {
        ;(async () => result && await props.onResult(result!))()
    }, [displayToast])

    const checkAnswer = async () => {
        const {familiarity, distance, attempt, reference} = gradeAnswer(referenceAnswer, answer, GRADING_LENIENCY_PERCENTAGE)
        console.log("reference : ", reference);
        console.log("attempt: ", attempt);
        console.log(`familiarity: ${familiarity}`);
        console.log(`distance ${distance}`);
        const lexemePair = {
            ...props.lexemePair,
            familiarity
        }
        const result = {
            lexemePair,
            progress: hasImproved(props.lexemePair.familiarity, familiarity),
            thinkingTimeSecs: processThinkingTime(thinkingStartTime.current!),
        } as ITestResult
        setResult(result)
        setDisplayToast(true)
    }

    const onKeydown = async (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault()
            await checkAnswer()
        }
    }

    const renderAnswerSection = () => <InputWithLanguageFlag
        inputText={answer}
        inputLanguage={context.loggedInUser?.learningLanguage || Language.UNKNOWN}
        onInputTextChanged={(event) => setAnswer(event.target.value)}
        onClearInputTextButtonClicked={() => setAnswer('')}
        onKeydown={onKeydown}
        placeholder={'Type your answer here'}
        autoFocus={true}
    />

    return <>
        <TestProgressMeter
            currentQuestion={props.currentIndex + 1}
            totalQuestions={props.testLength}
        />
        <Question lexemePair={props.lexemePair} />
        {renderAnswerSection()}
        {displayToast && result && createPortal(
            <ResultToast
                answer={answer}
                referenceAnswer={referenceAnswer}
                result={result}
                onDismissClicked={() => setDisplayToast(false)}
            />,
            document.body
        )}
    </>
}
