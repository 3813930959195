import { getMysqlDateIgnoreTZ, isValidMysqlDateString, IUserData, MySQLDate, ReminderFrequency } from 'czapp-shared'
import { isThePreviousDay, isTheSameDay } from 'czapp-shared'

export type ShouldShowReminderOptions = {
    currentStreakLength: number
    currentStreakEnd: MySQLDate | null
    today: MySQLDate
    lastShownDate: MySQLDate | null
    reminderFrequency: ReminderFrequency
}

export const shouldShowReminder = (
    options: ShouldShowReminderOptions
): boolean => {
    if (options.reminderFrequency === ReminderFrequency.NEVER) return false
    if (options.reminderFrequency === ReminderFrequency.ALWAYS) return true
    if (options.currentStreakLength < 2 || options.currentStreakEnd === null)
        return false
    const alreadyShownToday =
        options.lastShownDate === null
            ? false
            : isTheSameDay(options.lastShownDate, options.today)
    const output =
        isThePreviousDay(options.currentStreakEnd, options.today) &&
        !alreadyShownToday
    return output
}

export const isTestUnlocked = (userData: IUserData | undefined): boolean => {
    if (!userData) return false
    if (!userData.currentStreakEnd) return false
    if (!isValidMysqlDateString(userData.currentStreakEnd)) return false
    return (isTheSameDay(userData.currentStreakEnd, getMysqlDateIgnoreTZ(new Date())!))
}
