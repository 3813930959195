import { AppError, VocabCheckType } from 'czapp-shared'
import React, { useContext } from 'react'
import { createPortal } from 'react-dom';
import { Home } from './Home'
import { AddNu } from './overlay/AddNu';
import { Edit } from './overlay/Edit';
import { ErrorOverlay } from './overlay/ErrorOverlay'
import { HighStreak } from './overlay/HighStreak';
import { Overlay } from './overlay/Overlay';
import { Points } from './overlay/Points';
import { Recents } from './overlay/Recents';
import { Settings } from './overlay/Settings';
import { Streak } from './overlay/Streak';
import { VocabCheck } from './overlay/VocabCheck';
import { AppContext } from './RootComponent'
import { StreakReminder } from './overlay/StreakReminder'

export enum ActiveOverlay {
    NONE = 'none',
    SETTINGS = 'settings',
    RECENT = 'recent',
    ADD = 'add',
    EDIT = 'edit',
    STREAK = 'streak',
    HIGH_STREAK = 'highStreak',
    POINTS = 'points',
    TEST = 'test',
    FLASHCARDS = 'flashcards',
    PROGRESS = 'progress',
}

export const App = () => {
    const context = useContext(AppContext)

    const onOverlayClose = () => {
        context.setActiveOverlay(ActiveOverlay.NONE)
    }

    return (
        <div className="app">
            <Home />

            {context.activeOverlay === ActiveOverlay.ADD && createPortal(
                <Overlay onClose={onOverlayClose} bgColor="#418211"><AddNu /></Overlay>,
                document.body
            )}

            {context.activeOverlay === ActiveOverlay.SETTINGS && createPortal(
                <Overlay onClose={onOverlayClose} bgColor="white"><Settings /></Overlay>,
                document.body
            )}

            {context.activeOverlay === ActiveOverlay.RECENT && createPortal(
                <Overlay onClose={onOverlayClose} bgColor="#ffd58b"><Recents /></Overlay>,
                document.body
            )}

            {context.activeOverlay === ActiveOverlay.EDIT && createPortal(
                <Overlay onClose={onOverlayClose} bgColor="#fcc077"><Edit /></Overlay>,
                document.body
            )}

            {context.activeOverlay === ActiveOverlay.FLASHCARDS && createPortal(
                <Overlay bgColor="#317a97" onClose={onOverlayClose}>
                    <VocabCheck vocabCheckType={VocabCheckType.FLASHCARD} />
                </Overlay>,
                document.body
            )}

            {context.activeOverlay === ActiveOverlay.TEST && createPortal(
                <Overlay bgColor="#4fb2d8" onClose={onOverlayClose}>
                    <VocabCheck vocabCheckType={VocabCheckType.TEST} />
                </Overlay>,
                document.body
            )}

            {context.activeOverlay === ActiveOverlay.STREAK && createPortal(
                <Overlay onClose={onOverlayClose} bgColor="#ede9e2"><Streak /></Overlay>,
                document.body
            )}

            {context.activeOverlay === ActiveOverlay.HIGH_STREAK && createPortal(
                <Overlay onClose={onOverlayClose} bgColor="#ede9e2"><HighStreak /></Overlay>,
                document.body
            )}

            {context.activeOverlay === ActiveOverlay.POINTS && createPortal(
                <Overlay onClose={onOverlayClose} bgColor="#ede9e2"><Points /></Overlay>,
                document.body
            )}

            {context.appError !== AppError.NONE && <ErrorOverlay />}
            {process.env.DEV_MODE === 'true' && (
                <div id="devMode">DEV MODE</div>
            )}
            {context.showStreakReminderOverlay && <StreakReminder />}
        </div>
    )
}


{/*<Routes>*/}
{/*    <Route*/}
{/*        path="/home/recent"*/}
{/*        element={*/}
{/*            <ProtectedRoute>*/}
{/*                <Recent />*/}
{/*            </ProtectedRoute>*/}
{/*        }*/}
{/*    />*/}
{/*    <Route*/}
{/*        path="/home/add"*/}
{/*        element={*/}
{/*            <ProtectedRoute>*/}
{/*                <Add />*/}
{/*            </ProtectedRoute>*/}
{/*        }*/}
{/*    />*/}
{/*    <Route*/}
{/*        path="/home/edit"*/}
{/*        element={*/}
{/*            <ProtectedRoute>*/}
{/*                <Edit />*/}
{/*            </ProtectedRoute>*/}
{/*        }*/}
{/*    />*/}
{/*    <Route*/}
{/*        path="/home/settings"*/}
{/*        element={*/}
{/*            <ProtectedRoute>*/}
{/*                <Settings />*/}
{/*            </ProtectedRoute>*/}
{/*        }*/}
{/*    />*/}
{/*    <Route*/}
{/*        path="/home/streak"*/}
{/*        element={*/}
{/*            <ProtectedRoute>*/}
{/*                <Streak />*/}
{/*            </ProtectedRoute>*/}
{/*        }*/}
{/*    />*/}
{/*    <Route*/}
{/*        path="/home/points"*/}
{/*        element={*/}
{/*            <ProtectedRoute>*/}
{/*                <Points />*/}
{/*            </ProtectedRoute>*/}
{/*        }*/}
{/*    />*/}
{/*    <Route*/}
{/*        path="/home"*/}
{/*        element={*/}
{/*            <ProtectedRoute>*/}
{/*                <HomePage />*/}
{/*            </ProtectedRoute>*/}
{/*        }*/}
{/*    />*/}
{/*    <Route*/}
{/*        path="/"*/}
{/*        element={*/}
{/*            <ProtectedRoute>*/}
{/*                <HomePage />*/}
{/*            </ProtectedRoute>*/}
{/*        }*/}
{/*    />*/}
{/*    */}
{/*    <Route*/}
{/*        path="/learn"*/}
{/*        element={*/}
{/*            <ProtectedRoute>*/}
{/*                <LearnPage />*/}
{/*            </ProtectedRoute>*/}
{/*        }*/}
{/*    />*/}
{/*    <Route*/}
{/*        path="/learn/test"*/}
{/*        element={*/}
{/*            <ProtectedRoute>*/}
{/*                <Test />*/}
{/*            </ProtectedRoute>*/}
{/*        }*/}
{/*    />*/}
{/*    <Route*/}
{/*        path="/learn/flashcards"*/}
{/*        element={*/}
{/*            <ProtectedRoute>*/}
{/*                <Flashcards />*/}
{/*            </ProtectedRoute>*/}
{/*        }*/}
{/*    />*/}

{/*    <Route*/}
{/*        path="/export-csv"*/}
{/*        element={*/}
{/*            <ProtectedRoute>*/}
{/*                <ExportCsv />*/}
{/*            </ProtectedRoute>*/}
{/*        }*/}
{/*    />*/}
{/*    <Route*/}
{/*        path="/delete-all-user-data"*/}
{/*        element={*/}
{/*            <ProtectedRoute>*/}
{/*                <DeleteAllUserData />*/}
{/*            </ProtectedRoute>*/}
{/*        }*/}
{/*    />*/}
{/*    <Route path="/sign-in" element={<SignInPage />} />*/}
{/*</Routes>*/}
