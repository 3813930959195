import { AppError } from 'czapp-shared'
import { useContext, useEffect } from 'react'
import * as React from 'react'
import styled from 'styled-components'
import { useDictionary } from '../../dictionary/useDictionary'
import { AppContext } from '../RootComponent'

const ErrorOverlayContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
`

const ErrorOverlayContent = styled.div`
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    margin: 0 10vw;
    color: black;
`

const ErrorTitle = styled.h1`
    color: #595128;
    font-size: 3vh;
`

const ErrorMessage = styled.p`
    color: #838181;
    font-size: 2vh;
`

const CloseButton = styled.button`
    background-color: #d99a1c;
    color: #463905;
    padding: 3vw 7vw;
    border: none;
    border-radius: 10vw;
    cursor: pointer;
    font-size: 3vh;
    margin-top: 6vh;
`

export const ErrorOverlay = () => {
    const context = useContext(AppContext)
    const dictionary = useDictionary()

    useEffect(() => {
        console.log('ErrorOverlay mounted')
    }, [])

    return (
        <ErrorOverlayContainer>
            <ErrorOverlayContent>
                <ErrorTitle>{dictionary.ERROR_OVERLAY_HEADING}</ErrorTitle>
                <ErrorMessage>{context.appError}</ErrorMessage>
                <CloseButton onClick={() => context.setAppError(AppError.NONE)}>
                    {dictionary.BUTTON_CLOSE}
                </CloseButton>
            </ErrorOverlayContent>
        </ErrorOverlayContainer>
    )
}
