import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { isTestUnlocked } from '../util/Streak';
import { ActiveOverlay } from './App';
import { DictionaryCard } from './cards/DictionaryCard'
import { PointsCard } from './cards/PointsCard'
import { ProgressCard } from './cards/ProgressCard';
import { StreakCard } from './cards/StreakCard'
import { UnlockedTestCard } from './cards/UnlockedTestCard'
import { AlphaLogo } from './generic/AlphaLogo'
import { CzappLogo } from './generic/CzappLogo'
import { CardRow } from './layout/CardRow'
import { CUSTOM_BUTTON_LABEL, CustomButton } from './generic/CustomButton'
import { MainPageContent } from './layout/MainPageContent'
import { Navigation } from './Navigation'
import { AppContext, TopLevelPage } from './RootComponent'
import { FadeIn } from './layout/FadeIn'

const SettingsButtonContainer = styled.div`
    position: fixed;
    font-size: 5vw;
    top: 2vh;
    right: 2vh;
    button {
        font-size: min(7vw, 7vh) !important;
    }
`

const YourLevel = styled.div`
    font-size: 4vw;
    color: #8c8c8c;
    margin-top: 9vw;
    width: 100%;
    text-align: center;
    b {
        font-weight: bold;
    }
`

const YourProgress = styled.h2`
    font-size: 5vw;
    font-weight: bold;
    color: #414141;
    margin-top: 5vw;
    margin-bottom: 5vw;
    width: 100%;
    text-align: left;

    b {
        font-weight: bold;
    }
`

export const Home = () => {
    const context = useContext(AppContext)
    const [showTestNotification, setShowTestNotification] = useState(false)

    useEffect(() => {
        if (isTestUnlocked(context.loggedInUser)) {
            setShowTestNotification(true)
        }
    }, [])

    return (
        <>
            <MainPageContent>
                <FadeIn>
                    <SettingsButtonContainer>
                        <CustomButton
                            label={CUSTOM_BUTTON_LABEL.settings}
                            onClick={() => context.setActiveOverlay(ActiveOverlay.SETTINGS)}
                        />
                    </SettingsButtonContainer>

                    <CzappLogo />

                    <YourLevel>
                        Your level: <b>Cool Catalyst</b>
                    </YourLevel>

                    <AlphaLogo />

                    <UnlockedTestCard
                        setShowTestNotification={setShowTestNotification}
                        hidden={!showTestNotification}
                    />

                    {/*<YourProgress>Your progress</YourProgress>*/}

                    <CardRow>
                        <StreakCard />
                        <PointsCard />
                    </CardRow>

                    <CardRow>
                        <DictionaryCard onClick={() => context.setActiveOverlay(ActiveOverlay.RECENT)} />
                    </CardRow>

                    <CardRow>
                        <ProgressCard />
                    </CardRow>

                </FadeIn>
            </MainPageContent>

            <Navigation />
        </>
    )
}
