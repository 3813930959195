import { ILexemePair, Language, Lexeme } from 'czapp-shared'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { ActiveOverlay } from '../App';
import { AppContext } from '../RootComponent'

interface ILexemePairProps {
    data: ILexemePair
}

export const LexemePairRow = (props: ILexemePairProps) => {
    const context = React.useContext(AppContext)
    const navigate = useNavigate()

    const onLexemePairClicked = (event: React.MouseEvent) => {
        // @ts-expect-error any
        const enId = event.currentTarget.attributes['data-en_id'].value
        // @ts-expect-error any
        const czId = event.currentTarget.attributes['data-cz_id'].value
        const langToFocus: Language =
            // @ts-expect-error any
            event.currentTarget.attributes['data-lang_to_focus'].value

        const czechLexeme = new Lexeme(props.data.userId)
        czechLexeme.id = parseInt(czId)
        czechLexeme.text = props.data.czechLexeme.text

        const englishLexeme = new Lexeme(props.data.userId)
        englishLexeme.id = parseInt(enId)
        englishLexeme.text = props.data.englishLexeme.text

        context.setCurrentlyEditingLexemePair({
            czechLexeme: czechLexeme,
            englishLexeme: englishLexeme,
            langToFocus,
        })
        context.setActiveOverlay(ActiveOverlay.EDIT)
    }

    return (
        <tr className={props.data.familiarity}>
            <td
                onClick={onLexemePairClicked}
                data-en_id={props.data.englishLexeme.id}
                data-cz_id={props.data.czechLexeme.id}
                data-lang_to_focus={Language.ENGLISH}
            >
                {props.data.englishLexeme.text}
            </td>
            <td
                onClick={onLexemePairClicked}
                data-en_id={props.data.englishLexeme.id}
                data-cz_id={props.data.czechLexeme.id}
                data-lang_to_focus={Language.CZECH}
            >
                {props.data.czechLexeme.text}
            </td>
        </tr>
    )
}
