import React, { CSSProperties, useEffect } from 'react'

export enum CUSTOM_BUTTON_LABEL {
    mic = 'mic',
    close = 'close',
    cancel = 'cancel',
    recent = 'reorder',
    tests = 'psychology_alt',
    add = 'add',
    save = 'save',
    update = 'update',
    delete = 'delete',
    deleteForever = 'delete_forever',
    settings = 'settings',
    check = 'check',
    arrowForward = 'arrow_forward_ios',
}

export interface CustomButtonProps {
    label: string
    onClick?: () => void
    floatRight?: boolean
    size?: string
}

export const CustomButton = (props: CustomButtonProps) => {
    const [buttonStyle, setButtonStyle] = React.useState<
        CSSProperties | undefined
    >(undefined)

    useEffect(() => {
        setButtonStyle({
            fontSize: props.size,
            float: props.floatRight ? 'right' : 'none',
        })
    }, [props.size, props.floatRight])

    return (
        <button
            className="icon material-symbols-outlined customButton"
            style={buttonStyle}
            onClick={props.onClick}
        >
            {props.label}
        </button>
    )
}
